@charset "UTF-8";
/* CSS Document */

/* structural styles */
* {
	margin: 0;
	padding: 0;
	line-height: 130%;
	-webkit-text-size-adjust: 100%;
}
#footer {
	position: relative;
	-webkit-text-size-adjust: 100%;
	width: 1200px;
	margin: 12px auto;
	background: #fcf1ce !important;
}
#the_footer {
	position: relative;
	width: 1200px;
	margin: 0 auto;
}
#footer #siteseal {
	display: none;
	position: absolute;
	left: 0;
	top: 100px;
}
/* font styles */
body {
	/*background-color: #000;
	background: url("../images/bg.gif") repeat scroll center top transparent;*/
	font-family: "Lato", Geneva, sans-serif;
	font-size: 14px;
	color: #000;
}
body.noscroll {
	height: 100%;
	overflow: hidden;
}
#changePasswordPrompt{
	height: 25%;
	width: 50%;
	left:25%;
	top:35%;
	border:1px solid black;
	z-index: 9999999999999;
	position:fixed;
	background-color: #fff;
	text-align:center;
}

#changePasswordPrompt #formContent {
	left:25%;
}

#changePasswordPrompt #formContent h1 {

}
#changePasswordPrompt #formContent p{

}
#changePasswordPrompt #formContent p button{
}
#changePasswordPrompt #formContent input{
	height:30px;
}
.relative {
	position: relative;
}
body, #header, #content_wrapper {
	width: 1200px !important;
	margin: 0 auto !important;
}
h1 {
	font-size: xx-large;
	font-family: "Lato", Tahoma, Geneva, sans-serif;
	font-weight: 700;
	color: #000000;
	font-weight: normal;
}
.by_three h1, .for_inline {
	line-height: 100%;
	display: inline-block;
	width: 260px;
}
h2 {
	font-family: Tahoma, Geneva, sans-serif;
	color: #D4A017;
	font-size: 14px;
	margin-bottom: 10px;
}
h3 {
	text-transform: uppercase;
}
a {
	color: #3a86b1;
}
a img {
	border: none;
	border-width: 0;
}
.font-weight-regular{
	font-weight: normal;
}
/* header styles */
#header {
	width: 1200px;
	margin: 0 auto;
	text-align: center;
	padding-top: 8px;
	overflow: visible;
	height: 212px !important;
	position: relative;
}
.tagline {
	color: #000;
	font-size: 16px;
	margin-bottom: 16px;
	display: inline-block;
	font-weight: bold;
}
/* footer styles */
#footer {
	overflow: hidden;
	text-align: center;
}
#the_footer {
	clear: both;
	padding-bottom: 0px;
 border-top 1px solid #e5b143 !important;
}
#the_footer p {
	color: #000;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
}
#the_footer p a, #the_footer a {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
}
#the_footer a {
	color: #3A86B1;
}
/* main nav */
#nav {
	z-index: 999999;
	position: absolute;
	bottom: 0;
	left: 0;
}
#nav ul {
	list-style: none outside none;
	margin: 0;
	position: relative;
	padding: 0;
}
#nav ul li {
	font-family: Arial, Helvetica, sans-serif;
	color: #fff;
	/*background: url("../images/bg_menu1.gif") no-repeat scroll right top transparent;*/
	position: relative;
	float: left;
	margin: 0;
	padding: 0;
	z-index: 9999;
}
#nav ul li a.inactive {
	color: gray !important;
}
#nav ul .last {
	background: none repeat scroll 0 0 transparent;
	display: inline;
	float: left;
	margin: 0;
	padding: 0;
}
#nav ul li a {
	/*background: url("../images/bg_menu.gif") repeat-x scroll left top transparent;*/
	background-color: #e5b143;
	color: #000;
	display: block;
	float: left;
	line-height: 50px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	width: 170px;
	font-weight: bold;
	font-size: 18px;
}
#nav ul li ul li a, .dashboard #nav ul li.dashboard ul li a, .home #nav ul li.home ul li a, .library #nav ul li.library ul li a, .indicators #nav ul li.indicators ul li a, .login #nav ul li.login ul li a {
	width: 170px;
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;
}
#nav ul li ul:last-child {
	width: 170px !important;
}
#nav ul li a:hover:not(.inactive), .home #nav ul li.home a {
	/*background: url("../images/bg_menu-act.gif") repeat-x scroll left top transparent;*/
	background-color: #3a86b1;
	color: #fff;
	text-decoration: none;
}
#nav ul li ul li a:hover {
	/*background: url("../images/bg_menu-act.gif") repeat-x scroll left top transparent !important;*/
	background-color: #3a86b1;
	color: #fff;
	text-decoration: none;
	font-weight: bold;
}
#nav ul li a.current {
	/*background: url("../images/bg_menu-act.gif") repeat-x scroll left top transparent !important;*/
	background-color: #3a86b1;
	color: #fff !important;
	text-decoration: none;
}
#nav ul li ul li a.current {
	/*background: url("../images/bg_menu-act.gif") repeat-x scroll left top transparent !important;*/
	background-color: #3a86b1;
	color: #fff !important;
	text-decoration: none;
	font-weight: bold;
}
#nav ul li:hover ul, #nav ul li a:hover ul {
	display: block;
}
#nav ul li ul {
	position: absolute;
	top: 100%;
	z-index: 9999999;
	display: none;
	border-top: 1px solid #ccc;
	width: 170px;
	left: 0;
}
#nav ul li ul li {
	background: none;
	margin: 0;
	padding: 0;
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	position: relative;
	width: 170px;
}
#nav ul li ul li ul {
	visibility: hidden;
	position: absolute;
	left: -101%;
	left: calc(-100% - 2px);
	z-index: 999999;
	top: -1px;
}
#nav ul li ul li:hover ul {
	visibility: visible;
}
#nav ul li ul li a, .dashboard #nav ul li.dashboard ul li a, .home #nav ul li.home ul li a, .library #nav ul li.library ul li a, .indicators #nav ul li.indicators ul li a, .login #nav ul li.login ul li a {
	background: none #3A86B1;
	color: #fff;
	display: block;
	float: left;
	line-height: 125%;
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;
	width: 126px;
	padding: 10px 5px;
	font-size: 12px;
}
#nav ul li ul li a:hover {
	color: #fff !important;
	background: #000 !important;
}
#nav ul li.disabled a {
	pointer-events: none;
	color: #999;
}

/* sub nav */

.sub_nav {
	z-index: 10;
	position: absolute;
	bottom: 0;
	left: 0;
}
.sub_nav ul {
	list-style: none outside none;
	margin: 0;
	position: relative;
	padding: 0;
}
.sub_nav ul li {
	font-family: Arial, Helvetica, sans-serif;
	color: #fff;
	/*background: url("../images/bg_menu1.gif") no-repeat scroll right top transparent;*/
	position: relative;
	float: left;
	margin: 0;
	padding: 0;
	z-index: 9999;
}
.sub_nav ul .last {
	background: none repeat scroll 0 0 transparent;
	display: inline;
	float: left;
	margin: 0;
	padding: 0;
}
.sub_nav ul li a {
	/*background: url("../images/bg_menu.gif") repeat-x scroll left top transparent;*/
	background-color: #3a86b1;
	color: #fff;
	display: block;
	float: left;
	line-height: 35px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	width: 170px;
	font-weight: bold;
	font-size: 14px;
}
.sub_nav ul li ul li a {
	width: 170px;
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;
}
.sub_nav ul li ul:last-child {
	width: 170px !important;
}
.sub_nav ul li a:hover {
	/*background: url("../images/bg_menu-act.gif") repeat-x scroll left top transparent;*/
	background-color: #3a86b1;
	color: #fff;
	text-decoration: none;
}
.sub_nav ul li ul li a:hover {
	/*background: url("../images/bg_menu-act.gif") repeat-x scroll left top transparent !important;*/
	background-color: #3a86b1;
	color: #fff;
	text-decoration: none;
	font-weight: bold;
}
.sub_nav ul li a.current {
	/*background: url("../images/bg_menu-act.gif") repeat-x scroll left top transparent !important;*/
	background-color: #3a86b1;
	color: #fff !important;
	text-decoration: none;
}
.sub_nav ul li ul li a.current {
	/*background: url("../images/bg_menu-act.gif") repeat-x scroll left top transparent !important;*/
	background-color: #3a86b1;
	color: #fff !important;
	text-decoration: none;
	font-weight: bold;
}
.sub_nav ul li:hover ul, .sub_nav ul li a:hover ul {
	display: block;
}
.sub_nav ul li ul {
	position: absolute;
	top: 100%;
	z-index: 9999999;
	display: none;
	border-top: 1px solid #ccc;
	width: 170px;
	left: 0;
}
.sub_nav ul li ul li {
	background: none;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid #ccc;
	position: relative;
	width: 170px;
}
.sub_nav ul li ul li ul {
	visibility: hidden;
	position: absolute;
	left: -101%;
	left: calc(-100% - 2px);
	z-index: 999999;
	top: -1px;
}
.sub_nav ul li ul li:hover ul {
	visibility: visible;
}
.sub_nav ul li ul li a {
	background: none #3A86B1;
	color: #fff;
	display: block;
	float: left;
	line-height: 125%;
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;
	width: 170px;
	padding: 10px 5px;
	font-size: 12px;
}
.sub_nav ul li ul li a:hover {
	color: #fff !important;
	background: #000 !important;
}
.sub_nav ul li.disabled a {
	pointer-events: none;
	color: #999;
}
/* main content wrapper styles */
#content_wrapper {
	width: 1198px;
	margin: 0 auto;
	text-align: justify;
	padding-top: 14px;
	min-height: 400px;
	overflow: hidden;
	position: relative;
}
.assignedinds #content_wrapper{
	min-height: 0 !important;
}
.dashboard #content_wrapper, .manage_users #content_wrapper, .manageusers #content_wrapper, .assignedinds #content_wrapper, .specificMonth #content_wrapper, .cusInd #content_wrapper, .q-audit #content_wrapper {
	padding-top: 15px;
	overflow: visible;
}
.qaudit #content_wrapper
{
	padding-top: 0 !important;
}
.assignedinds #content_wrapper {
	overflow: visible;
}
#content_wrapper p {
	margin: 12px 0;
}
#content_wrapper .margin-left{
	margin-left: 14px !important;
}
#content_wrapper ul {
	list-style-image: url('../images/list_bullet.png');
	list-style-position: outside;
	margin-left: 14px;/*color: #e5b143;*/
}
/* color styles */
.yellow_highlight {
	font-weight: bold;
	color: #000;
}
.dark_yellow_highlight {
	font-weight: bold;
	color: #000;
}
th {
	border-collapse: collapse;
	font-size: small;
	padding-left: 5px;
	height: 28px;
	line-height: 29px !important;
	color: #000;
	font-family: Arial, Helvetica, sans-serif;
	background: #fcd984;
	text-transform: uppercase;
	border: 1px solid #000;
	-webkit-appearance: none;
}
#qapi_library td {
	border-collapse: collapse;
	border: 1px solid #000;
	font-size: 12px;
	text-align: left;
	padding-left: 3px;
	height: 15px;
	padding: 3px;
}
#qapi_library td a {
	font-size: 12px;
}
#qapi_library tr.table_header td
{
	border-collapse: collapse;
	font-size: small;
	padding-left: 5px;
	height: 28px;
	line-height: 29px !important;
	color: #000;
	font-family: Arial, Helvetica, sans-serif;
	background: #fcd984;
	text-transform: uppercase;
	border: 1px solid #000;
	-webkit-appearance: none;
	font-weight: bold;
	padding: 2px 0 0 5px !important;
}
.alignCenter {
	text-align: center;
}
.leftBucketLibraryUpload {
	float: left;
	margin-right: 10px;
}
#disclaimer {
	margin-bottom: 15px;
}
#disclaimer p {
	text-align: justify;
	font-size: 9px;
	line-height: 14px;
	color: #000;
}
#suggest_content p {
	margin: 2px 0;
}

.button-style {
	line-height: 1em;
	cursor: pointer;
	background-color: #e5b143;
	padding: 10px;
	margin-right: 0px !important;
	margin-top: 0px !important;
	font-weight: bold;
	font-size: small;
	font-family: Arial, Helvetica, sans-serif;
	border: 0px solid #000;
	min-width: 36px;
	text-transform: uppercase;
}
.button-style:hover, .button-style:focus, .button-style:active {
	line-height: 1em;
	cursor: pointer;
	background-color: #e5b143;
	padding: 10px;
	margin-right: 0px !important;
	margin-top: 0px !important;
	font-weight: bold;
	font-size: small;
	font-family: Arial, Helvetica, sans-serif;
	border: 0px solid #000;
	min-width: 36px;
	text-transform: uppercase;
}
.button {
	cursor: pointer;
	border-style: none;
	border-color: inherit;
	width: 113px;
	padding: 0;
	color: #fff;
	height: 45px;
	background-color: #3A86B1;
	border-width: 0;
	font-size: 18px;
	margin-left: 150px;
}
.button:hover{
	background-color: #34789f;
}
.button-style-2{
	cursor: pointer;
	border-style: none;
	border-color: inherit;
	width: auto;
	height: auto;
	padding: 5px 10px;
	color: #fff;
	background-color: #3A86B1;
	border-width: 0;
	font-size: 18px;
	display: inline-block !important;
	text-decoration: none !important;
}
.button-style-3{
	cursor: pointer;
	height: 20px;
	font-size: small;
	padding: 5px 6px 3px;
	font-weight: bold;
	background: #e5b143;
	color: #000;
	line-height: 20px;
	text-transform: uppercase;
	font-family: Arial, Helvetica, sans-serif;
	text-decoration: none;
}
.button-style-3:hover, .button-style-3:active, .button-style-3:focus
{
	color: #fff;
	background: #3A86B1;
}
.bt-sm, .btn-sm{
	font-size: 16px !important;
	line-height: 16px !important;
	width: 100px !important;
	height: 35px !important;
}
.btn-xs{
	font-size: 14px !important;
	line-height: 14px !important;
	width: 75px !important;
	height: 35px !important;
}
.by_three {
	width: 33%;
	float: left;
}
.nomargin{
	margin: 0 !important;
}
#content_wrapper .by_three p, #content_wrapper .by_three p a {
	color: #D4A017;
	font-family: Times, Helvetica, sans-serif;
	text-decoration: none;
	font-size: x-large;
	margin: 0;
}
.by_three p a:hover {
	text-decoration: underline;
}
.select_wrap {
	overflow: hidden;
	width: 265px;
	background-color: #3a86b1;
	border: 0px solid #D4A017;
	height: 26px;
	line-height: 26px;
	margin: 0 auto;
	padding-top: 3px;
}
.select_wrap.whiteArrow {
	margin: 0 0 0 4px;
	display: inline-block;
	border: 1px solid #727272;
	width: 224px;
	background-color: #fff;
}
.by_three select, select.whiteDropDown {
	width: 200px;
	background-color: #3a86b1;
	font-size: 18px;
	height: auto;
	border: none !important;
	color: #fff;
	margin-top: -3px;
	text-overflow: '';
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 0.01px;
}
.by_three select::-ms-expand{
	display: none !important;
}
select.whiteDropDown {
	/*background-image: url(../images/select_dropdown_carrot_white_black.png); */
	background-color: #fff;
	float: left;
	color: #727272;
	width: 245px;
}
.clear {
	clear: both;
	float: none;
}
.align_right {
	text-align: right;
}
.align_left {
	text-align: left;
}
.manage_users input[type="text"], input[type="text"].text_input {
	border: none;
	height: 30px;
	width: 100%;
	font-size: 18px;
	background-color: #fff;
	color: #727272;
	text-indent: 3px;
	font-size: 18px;
}
.manageusers input[type="text"], .manageusers input[type="password"] {
	height: 32px;
	width: 100%;
	background-color: #fff;
	color: #727272;
	text-indent: 5px !important;
	font-size: 18px;
}
.manageusers input[type="password"]{
	-webkit-appearance: none;
	border-radius: 0;
	border: 0px solid #ccc;
}
.input-font-style{
	font-size: 18px !important;
	color: #727272 !important;
}
.manage_users td {
	border-right: 0px solid #000;
}
.manage_users tr {
	display: inline-block;
	margin: 1px 0;
}
.whiteBg {
	background-color: #fff;
}
.noBorder, td.noBorder, .manage_users td.noBorder {
	border: none !important;
	border-width: 0 !important;
}
button {
	line-height: 1em;
	cursor: pointer;
	background-color: #e5b143;
	padding: 10px;
	margin-right: 0px !important;
	margin-top: 0px !important;
	font-weight: bold;
	font-size: small;
	font-family: Arial, Helvetica, sans-serif;
	border: 0px solid #000;
	min-width: 36px;
	min-height: 27px;
	text-transform: uppercase;
}

.qmetricsButton {
	color:black;
	background-color:white;
	text-decoration:underline;
	outline:none;
	margin-top:-2px !important;
	margin-bottom:-2px;
	height:15px;
	font-weight:normal;
}

.yellowGradientBg {
	background: #e5b143;
}
/** 2 col layout **/

#side_bar, #content {
	padding: 1px 0;
}
#side_bar {
	width: 240px;
	margin-right: 100px;
}
#side_bar h2 {
	color: #000;
	font-family: "Lato";
	font-size: large;
	height: 45px;
	margin-left: 0;
	width: 200px;
	padding: 10px 0 0 14px;
	font-weight: normal;
}
#content {
	float: right;
	width: 680px;
}
#content.medium {
	width: 960px;
}
/** login layout **/
.login #content {
	padding-top: 1px;
	width: 720px;
}
.login input[type="text"], .login input[type="password"], input[type="email"] {
	font-size: 20px;
	height: 30px;
	width: 250px;
	background-color: #fff;
	border: 1px solid #b5b5b5;
	color: #b5b5b5;
	padding-left: 5px;
}
.login button, .register button, .snfqapi-btn {
	border-style: none;
	border-color: inherit;
	width: 119px;
	padding: 0;
	color: #fff;
	height: 45px;
	background: #3A86B1;
	border-width: 0;
	font-weight: normal;
	font-size: 18px;
}

.snfqapi-btn {
	display: inline-block;
	min-width: 119px;
	width: auto;
	text-decoration: none;
	height: auto;
	padding: 10px;
	text-transform: uppercase;
}

.login #disclaimer, .home #disclaimer {
	display: none;
}
.login p {
	font-size: medium;
}
.login h1 {
	margin-bottom: 45px;
}
.login #content_wrapper form p {
	margin: 15px 0;
}
.login #content_wrapper form {
	margin-bottom: 45px;
}
label {
	color: #000;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-size: medium;
}
.error, .search_msg {
	color: #60b04c !important;
}
#content_wrapper .border_red, #content_wrapper .border_blue, #content_wrapper .border_green, #content_wrapper .border_teal, #content_wrapper .border_oj, #content_wrapper .border_teal_two {
	padding-left: 17px;
	width: 248px;
	margin-bottom: 17px;
}
#content_wrapper .border_teal_two{
	margin-bottom: 0 !important;
}
body.login #master_wrapper{
	margin-bottom: 17px;
}
#content_wrapper .coming_soon {
	float: left;
	margin-right: 2%;
	width: 21% !important;
	padding: 5%;
}
.border_red {
	border: 1px solid #FF0000;
}
.border_purple {
	border: 1px solid #514163;
}
.border_blue {
	border: 1px solid #3366FF;
}
.border_green {
	border: 1px solid #99FF66;
}
.border_teal {
	border: 1px solid #75D6E5;
}
.border_oj {
	border: 1px solid #E5592A;
}
.border_teal_two {
	border: 1px solid #2DB5B5;
}
.makeItYellow {
	color: #E5AD44;
}
/*== featured box begin ==*/
#featured {
	width: 714px;
	position: relative;
	height: 341px;
	border: 1px solid #282828;
	background: #000;
	padding: 7px;
	overflow: hidden
}
#featured ul.ui-tabs-nav {
	position: absolute;
	top: 7px;
	left: 496px;
	list-style: none;
	padding: 0;
	margin: 0;
	width: 188px;
	z-index: 100;
	height: 341px;
	overflow: hidden
}
#featured ul.ui-tabs-nav li {
	float: left;
	width: 188px;
	height: 34px;
	overflow: hidden;
	font-size: .91em;
	line-height: 1.5em;
	padding: 0 0 0 7px;
	background: none;
	overflow: hidden
}
#featured ul.ui-tabs-nav li a {
	color: #000;
	display: block;
	background: url(../images/but.png) top left no-repeat;
	padding-left: 26px;
	width: 162px;
	line-height: 34px;
	text-decoration: none;
	font-size: 13px;
	font-weight: normal
}
#featured ul.ui-tabs-nav li a strong {
	text-transform: uppercase
}
#featured ul.ui-tabs-nav li a:hover {
	color: #000000;
	display: block;
	background: url(../images/but-act.png) top left no-repeat;
	text-decoration: none
}
#featured ul.ui-tabs-nav li.ui-tabs-selected a {
	color: #000000;
	display: block;
	background: url(../images/but-act.png) top left no-repeat;
	text-decoration: none;
}
#featured ul.ui-tabs-nav li.last {
	padding-right: 0;
}
#featured ul.ui-tabs-nav li.ui-tabs-selected {
}
#featured ul.ui-tabs-nav li.ui-tabs-selected div {
}
#featured .ui-tabs-panel .info {
	width: 512px;
	height: 71px;
	background: url(../images/bg_3.png) left top;
	position: absolute;
	left: 7px;
	top: 275px;
	color: silver;
	line-height: 14px;
	font-size: .91em;
}
#featured .info .inner {
	padding: 15px 0 0 15px;
}
#featured .ui-tabs-hide {
	display: none;
}
#featured p {
	font-size: small;
	font-family: Tahoma, Geneva, sans-serif;
}
#featured ul.ui-tabs-nav .inner a {
	height: 26px;
	padding-bottom: 8px;
}
/*== featured box end ==*/



/* HOME PAGE */


@font-face {
	font-family: monotypeCorsiva;
	src: url(_includes/Monotype Corsiva);
}
.home #content_wrapper {
	text-align: left;
}
.home #content {
	width: 735px;
}
.home #side_bar {
	background-color: #000;
	border: 1px solid #282828;
	padding-top: 0;
	margin-top: 15px;
	height: 885px;
	width: 230px;
}
.home h1 {
	line-height: 80%;
	font-family: Lato, "Lato";
}
#home_buckets_wrap {
	margin-top: 15px;
}
#bucket_left {
	float: left;
	width: 484px;
}
#bucket_right {
	border-left: 10px solid #DBB62F;
	float: left;
	margin-left: 10px;
	padding-left: 10px;
	width: 209px;
}
#bucket_right h1 {
	margin-bottom: 10px;
}
#bucket_right p {
	margin: 4px 0;
	color: #fff;
}
.accordion dt {
	color: #BF9513;
	font-family: Tahoma, Geneva, sans-serif;
	font-size: large;
	line-height: 43px;
	margin-bottom: 1px;
	padding: 0 0 0 16px;
	text-transform: none;
	background: url("../images/button-1.gif") no-repeat scroll left top transparent;
	cursor: pointer;
}
.accordion dt.active {
	background: url("../images/button-2.gif") no-repeat scroll left top transparent;
}
#content_wrapper .accordion dd ul.ul {
	padding: 22px 0 25px 34px;
	list-style-image: none;
	list-style: none;
}
#content_wrapper .accordion dd ul.ul li {
	margin: 3px 0;
}
.accordion dd ul.ul li a {
	color: #000;
	text-decoration: none;
	font-family: Tahoma, Geneva, sans-serif;
	font-size: large;
}
.address {
	padding: 25px 0 20px 25px;
	font-size: 115%;
}
.seal_of_excellence {
	width: 91px;
	margin: 15px auto;
}
.social_icons {
	text-align: center;
}
.social_icons img {
	margin: 0 2px;
}
#publishBtn, #emailThirdLevel, #generatePDF {
	width: 110px;
	display: table;
	float: right;
	text-align: center;
	margin: 0;
}
#publishBtn {
	display: table;
	cursor: pointer;
}
#generatePDF {
	display: none;
}
.indicators #publishBtn, .assignedinds #publishBtn, .specificMonth #emailThirdLevel, .dashboard #emailThirdLevel {
	display: block;
}
.home #footer {
	display: none;
}
#home_buckets_wrap #the_footer {
	width: 480px;
	text-align: center;
}
#home_buckets_wrap #the_footer p {
	margin: 0;
}
/* dashboard styles */

#containment_wrapper {
	width: 430px;
	margin-left: 461px;
	height: 15px;
}
#containment_wrapper.small {
	margin-left: 636px;
}
#survey_toggle {
	background-position: top center;
	background-repeat: no-repeat;
	border-bottom: 1px solid red;
	cursor: move;
	color: red;
	display: block;
	font-size: 43px;
	font-weight: bold;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 23px;
	padding-top: 3px;
	padding-bottom: 3px;
	position: relative;
	text-align: center;
	width: 42px;
}
 .#3A86B1Border {
 border-right: 3px solid #ff0000 !important;
}
.dashboard #content_wrapper, .specificMonth #content_wrapper {
	overflow: visible;
}
.table_row {
	display: block;
	clear: both;
	margin: 0;
	padding: 0;
	border-left: 1px solid #000;
	width: 100%;
	z-index: 99999;
}
.table_row_users {
	display: block;
	clear: both;
	margin: 0;
	padding: 0;
	float: right;
}
.indicator-category-professions-div {
	z-index: 99999999;
	width: 700px;
	min-height: 200px;
	margin-top: 5px;
	margin-right: -1px;
	background: #fff;
	position: absolute;
	right: 0;
	border: 1px solid #000;
	background-color: #faf4e2 !important;
	cursor: default;
}
.indicator-category-professions-div.title-top {
	bottom: 28px !important;
}
.indicator-category-professions-div > p {
	border-bottom: 1px solid #000;
	height: 20px;
	color: #fff;
	background-color: #3a86b1;
	margin-top: 0 !important;
	padding-top: 10px;
	padding-bottom: 2px;
	overflow: hidden;
}
.indicator-category-professions-div i{
	cursor: pointer;
}
.indicator-category-professions-div > ul {
	list-style: none !important;
	vertical-align: top;
}
.indicator-category-professions-div > ul > li{
	padding: 0 10px;
}
.indicator-category-professions-div > ul > li:first-child {
	margin-top: 0 !important;
}
.users-row select.usr_prof {
	height: 100% !important;
	border-radius: 0 !important;
	color: #727272;
	font-size: 18px;
	display: block !important;
	padding-left: 4px !important;
}
.users-row select.usr_prof.blank {
	color: light gray;
}
.deleteUser > img {
	width: 19px;
}
.dash_cell {
	float: left;
	position: relative;
}
.dash_cell_header {
	font-size: small;
	text-transform: uppercase;
	height: 21px;
	padding-top: 7px;
	color: #000;
	font-family: Arial, Helvetica, sans-serif;
	background: #e5b143;
	text-align: center;
	border-right: 1px solid #000;
	border-top: 1px solid #000;
	font-weight: bold;
	border-bottom: 1px solid #000;
}
.dash_cell_header a {
	color: #000;
	padding: 8px 3px;
}
.dash_division {
	background-color: #F9EBC6;
	border-right: 1px solid #000;
	height: 32px;
	border-bottom: 1px solid #000;
}
.assignedinds .table_row, .indicators .table_row {
	overflow: hidden;
}
.assignedinds .table_row .aind_cell_div, .assignedinds .table_row .dash_cell_header {
	border-bottom: 1px solid #000;
}
.assignedinds .dash_cell_header {
	border-bottom: 0 none;
}
.assignedinds .dash_cell_header {
	border-bottom: 0 none;
}
.dashboard .dash_division.division_3, .q-audit .dash_division.division_3 {
	display: table;
}
#content_wrapper .dash_division p {
	margin: 0;
	color: #000;
}
.larger_row_font #content_wrapper .dash_division p {
	margin: 0;
	color: #000;
	padding: 8px;
	font-size: 16px;
}
#content_wrapper .dash_division.division_1 p, #content_wrapper .dash_division.division_2 p {
	padding-top: 9px;
}
#content_wrapper .dash_division.division_3 p {
	display: table-cell;
	vertical-align: middle;
}
.dash_division.division_1 p, .dash_division.division_2 p, .dash_division.division_3 p {
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bold;
	line-height: 100%;
	letter-spacing: -.5px;
}

body.dashboard .dash_division.division_1 p, body.dashboard .dash_division.division_2 p, body.dashboard .dash_division.division_3 p {
	font-size:13.75px !important;
	vertical-align: middle !important;
}

#content_wrapper .dash_division.division_4 p {
	padding-left: 1px;
	padding-top: -2px;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 13.25px !important;
	font-weight: bold;
	text-align: left;
	vertical-align: middle !important;
	line-height: 26px;
	letter-spacing: -1px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.defPop.halfwidth{
	width: 300px !important;
}
.defPop.quarterwidth{
	width: 150px !important;
}
#content_wrapper .dash_division.division_4 .defPop p, .hover-popup p{
	white-space: normal;
	width: auto;
	line-height: 100%;
}
.dash_cell_header img {
	cursor: pointer;
}
#dashboard_assign_tools, .tools {
	display: none;
	z-index: 99999;
	position: absolute;
	top: 29px;
	right: 0;
	color: #fff;
	background-color: #3a86b1;
	padding: 0;
	border: 1px solid #000;
	width: 138px;
	overflow: hidden;
	font-family: Arial, Geneva, san-serif;
	font-weight: bold;
}
#content_wrapper .tools p, #dashboard_assign_tools p, .tools p {
	font-size: 14px;
	background-color: #3a86b1;
	margin: 0;
	text-align: center;
}
#dashboard_assign_tools p a, .tools p a {
	background-color: #3a86b1;
	border-bottom: 1px solid #ccc;
	border-right: 0px solid #ccc;
	border-left: 0px solid #ccc;
	color: #ffffff;
	display: block;
	font-size: 12px;
	font-weight: 700;
	padding: 10px;
	text-align: left;
}
#dashboard_assign_tools p a:hover, .tools p a:hover {
	background-color: #000;
	color: #fff;
}
.division_1 {
	width: 68px;
	border-left: 1px solid #000;
}
.division_1.small {
	width: 31px;
	border-left: 1px solid #000;
}
.indicator_row .dash_division.division_1.small {
	width: 30px;
	text-align: center !important;
}
.manageusers .division_1, .manageusers .division_2 {
	width: 175px;
}
.manageusers .division_3 {
	width: 260px;
}
.manageusers .division_4 {
	width: 180px;
}
.manageusers .division_17 {
	width: 138px;
	overflow: hidden;
	background-color: #F9EBC6;
}
.manageusers .division_17 button {
	box-sizing: border-box;
	width: 50%;
	text-align: center;
	padding: 9px 0;
	color: #000 !important;
}
.manageusers .division_17 button.updateUser {
	border-right: 1px solid #000;
}
.manageusers .division_17 button.updateUser[rel="NEW"], .manageusers .division_17 button.updateUser[rel="NEWM"], .manageusers .division_17 button.updateUser.admin {
	width: 100%;
	border-right: none;
}
.division_2 {
	width: 36px;
}
.dashboard .indicator_row .dash_division.division_2, .q-audit .indicator_row .dash_division.division_2 {
	width: 37px;
	text-align: center !important;
}
.division_3 {
	width: 103px;
}

.dashboard .indicator_row .dash_division.division_3, .q-audit .indicator_row .dash_division.division_3 {
	width: 108px;
	padding-left: 3px;
}
.division_4 {
	width: 462px;
}

.manageusers .division_1, .manageusers .division_2 {
	width: 123px;
}
.manageusers .division_3 {
	width: 265px;
}
.manageusers .division_4 {
	width: 170px;
}
.q-audit .dash_cell.small.division_1 {
	width: 31px;
}
.q-audit .dash_cell.small.division_2 {
	width: 36px !important;
}
.qrisk .dash_cell.division_1.small
{
	width: 30px !important;
}
.qrisk .dash_cell.division_2.small
{
	width: 37px !important;
}
.qrisk .dash_cell.division_3.small
{
	width: 111px !important;
}
.qrisk .dash_cell.division_4.small
{
	width: 632px !important;
}
.manageusers .division_profession {
	width: 220px;
}
.manageusers .division_profession > select {
	width: 100%;
}
.dashboard .indicator_row .dash_division.division_4, .q-audit .indicator_row .dash_division.division_4 {
	height: 29px;
	padding-top: 3px;
	padding-left: 3px;
	width: 451px;
}
.dashboard.qrisk .indicator_row .dash_division.division_4 {
	width: 629px;
}
.qrisk .division_4 {
	width: 640px;
}
.q-audit .indicator_row .dash_division.division_4 {
	width: 599px;
}
.q-audit .dash_cell_header.division_4 {
	width: 602px;
}
.division_5 {
	width: 34px;
}
.division_6 {
	width: 34px;
}
.division_7 {
	width: 34px;
}
.division_8 {
	width: 34px;
}
.division_9 {
	width: 34px;
}
.division_10 {
	width: 34px;
}
.division_11 {
	width: 34px;
}
.division_12 {
	width: 34px;
}
.division_13 {
	width: 34px;
}
.division_14 {
	width: 34px;
}
.division_15 {
	width: 34px;
}
.division_16 {
	width: 34px;
}
.division_17 {
	width: 94px;
}
.division_17.small {
	width: 138px;
	background-color: #e5b143;
}
#table_header {
	position: relative;
}
#table_header-sticky-wrapper.is-sticky #dashboard_assign_tools, #table_header-sticky-wrapper.is-sticky #configure_dashboard_wrapper {
	position: relative !important;
	right: 140px !important;
}
a.deleteUser {
	zoom: 1;
	vertical-align: middle;
	display: block;
	height: 23px;
	color: grey;
	text-decoration: none;
	padding-top: 10px;
	font-size: 16px;
	overflow: hidden;
}
a.deleteUser:after {
	content: ' ';
	clear: both;
	display: table;
	height: 0;
	visibility: hidden;
	font-size: 0;
}
.pop_up{
	background-color: #000;
	position: fixed !important;
	top: 315px;
	left: 50%;
	text-align: center;
	width: 300px;
	margin-left: -165px;
	border: 3px solid #D4A017;
	z-index: 99999992;
	padding: 15px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
}
.pop_up.wider{
	width: 500px;
	margin-left: -250px;
}
.pop_up.gallery {
	padding: 20px 20px !important;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	zoom: 1 !important;
	margin: 0px auto !important;
	left: 0 !important;
	text-align: inherit !important;
	position: absolute !important;
	min-height: 450px;
}
.pop_up.facesheet, .pop_up.findings_window, .pop_up.default {
	background-color: #508aac !important;
}
.hidden{
	display: none;
}
.pop_up.wide {
	padding: 12px 20px !important;
	width: 800px;
	left: 50%;
	margin-left:-400px !important;
	position: absolute !important;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	zoom: 1 !important;
}
.pop_up.findings_window{
	min-height: 650px;
	min-width:800px;
}

.pop_up.wide ul {
	margin-left: 20px !important;
}
.pop_up textarea, .pop_up input, .pop_up .mce-tinymce, .pop_up .custom-select{
	outline: none;
	font-size: 15px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.pop_up.wide.fixed_position{
	position: fixed !important;
}
.pop_up.wide textarea {
	border: none;
	border-width: 0;
	width: 100%;
	padding: 3px;
	display: block;
}
.pop_up.gallery #closeButton {
	position: absolute;
	bottom: 20px;
	right: 20px;
}
.pop_up.wide textarea.findings {
	background-color: #efefef;
	height: 90px;
	font-size: 14px !important;
}
.pop_up #saveButton, .pop_up .thumbs {
	display: block;
	max-height: 65px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.pop_up #saveButton, .pop_up #cancelButton, .pop_up #uploadButton {
	margin-right: 10px !important;
	display: inline-block;
}
.pop_up #saveButton {
	margin-right: 0 !important;
}
.pop_up #uploadButton {
	float: left;
	background: none !important;
}
.pop_up #uploadButton {
	display: inline-block !important;
	padding: 10px 0 0 0 !important;
}
.pop_up #uploadButton img {
	height: 42px;
	margin-top: -15px !important;
	vertical-align: middle;
}
.pop_up #photoUploadSpinner {
	margin-left: 10px !important;
	height: 32px !important;
	display: none;
}
.pop_up #popup_buttons {
	display: block;
	zoom: 1 !important;
	position: relative;
	bottom: 0;
	width: 100%;
	height: auto;
	left: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 8px 0;
}
.pop_up .thumbs {
	float: left;
	text-align: left;
	width: 100%;
	padding: 0 40px;
	position: relative;
	margin: 15px 0;
	overflow: hidden;
}
.pop_up .thumbs.noslider {
	padding: 0 !important;
}
.pop_up .thumbs.noslider ul.thumbs-slider {
	width: 100% !important;
	display: table !important;
}
.pop_up .thumbs.noslider li {
	display: table-cell !important;
	vertical-align: top !important;
}
.pop_up .thumbs.noslider a img {
	display: inline !important;
	margin: 0 !important;
}
.pop_up .thumbs.noslider a {
	display: inline !important;
	height: auto !important;
	text-transform: none !important;
}
.pop_up.wide .lSSlideWrapper {
	position: static !important;
}
.pop_up.wide .thumbs ul {
	list-style: none;
	max-height: 65px;
}
.pop_up.wide .thumbs li {
	display: inline-block;
	overflow: hidden;
}
.pop_up.wide .thumbs.slider {
	text-align: center;
}
.pop_up.wide .thumbs a {
	display: inline-block;
	height: 65px;
}
.pop_up .thumbs a span {
	font-size: 12px;
}
.pop_up .thumbs a img {
	display: block;
	margin: 0px auto;
}
.pop_up .noslider-wrap {
	display: inline-block;
	text-align: center;
	overflow: hidden;
}
.pop_up .noslider-wrap span {
	display: block;
	line-height: 100%;
}
.pop_up #fine-uploader {
	margin: 8px 0;
}
.lSAction a {
	height: 30px !important;
}
.lSAction span {
	font-size: 33px !important;
	line-height: 1em !important;
}
.lSAction .next_dep {
	margin-left: 0 !important;
}
.lSPrev {
	left: 0 !important;
}
.lSNext {
	right: 0 !important;
}
.pop_up p {
	color: #E5B143;
}
.pop_up.findings_window p {
	color: #ffffff;
}
.pop_up.gallery p, .pop_up.wide p {
	margin: 8px 0;
}
.pop_up .stephenMargin {
	margin: 18px 0 10px 0 !important;
}
.pop_up.gallery p > span, .pop_up.wide p > span {
	color: #fff !important;
	font-size: 70% !important;
	vertical-align: middle;
}
.html_template *, .html_template p > span, .pop_up.wide p > span {
	color: #000 !important;
	font-size: 14px !important;
	font-family: "Times New Roman", sans-serif;
}
.html_template h2 {
	font-size:18px !important;
}
.html_template p:empty {
	margin:12px 0px !important;
}
.html_template h3 {
	font-size:16px !important;
}
.neg-10-margin {
	margin-top: -10px !important;
}
.pop_up p a {
	text-decoration: underline;
}
.pop_up .spinnerwrap {
	position: absolute;
	left: 50%;
	margin-left: -16px;
}
.pop_up .popup_heading_table {
	display: table;
	width: 100%;
	min-height: 22px;
	margin: 6px 0 8px 0;
	table-layout: fixed;
}
.pop_up .popup_heading_table > .column {
	display: table-cell;
	border-spacing: 0px;
	vertical-align: middle;
}
.pop_up .popup_heading_table > .column p {
	font-size: 125% !important;
	line-height: 100% !important;
	display: inline;
	margin: 0px 8px !important;
}
.pop_up .popup_heading_table > .column a {
	line-height: 100% !important;
	font-size: 125% !important;
}
.pop_up .popup_heading_table > .column:first-of-type {
	text-align: left !important;
}
.pop_up .popup_heading_table > .column p:first-of-type {
	margin-left: 0 !important;
}
.pop_up .popup_heading_table > .column:last-of-type {
	text-align: right !important;
}
.pop_up .popup_heading_table .column.first {
	width: 10%;
}
.pop_up .popup_heading_table .column.forth p {
	vertical-align: middle;
}
.pop_up .popup_heading_table .column.first.shorter {
	width: 10%;
}
.pop_up .popup_heading_table .column.forth.shorter {
	width: 19%;
}
.pop_up .popup_heading_table .column.second {
	width: auto;
	text-align: center;
}
.pop_up .popup_heading_table .column.second.longer {
	width: 70%;
	text-align: center;
}
.pop_up .popup_heading_table .column.third {
	width: 10%;
}
.pop_up .popup_heading_table > .column p:last-of-type {
	margin-right: 0 !important;
}
.pop_up .column .adverse_event_col_version {
	width: auto !important;
	width: calc(100% + 15px) !important;
	left: auto !important;
	bottom: auto !important;
	height: 22px !important;
	line-height: 23px !important;
}
.pop_up .closeItDown img{
	max-height: 40px;
}
.pop_up .closeItDown i{
	color: #e5b143 !important;
}
.imageBox {
	cursor: move;
	width: 940px;
	top: 50px;
	left: 50%;
	margin-left: -490px;
	min-height: 400px;
	min-width: 400px;
	z-index: 100000001;
	position: absolute !important;
	border: 3px solid #D4A017;
	border-radius: 8px;
	padding: 20px;
	background: #000;
}
.imageBox #originalsizeimage {
	max-width: 49%;
	max-height: 400px;
	display: inline-block;
	margin: 0px auto;
	margin-right: 1%;
}
.imageBox #metaData {
	display: inline-block;
	vertical-align: top;
	float: right;
	width: 49%;
	margin-left: 1%;
	text-align: left;
}
.imageBox p {
	color: #e5b143;
	font-size: 125%;
	margin: 10px 0;
}
.imageBox #metaData p:first-of-type {
	margin-top: 0px;
	line-height: 1em;
}
.imageBox input::-webkit-input-placeholder, .imageBox textarea::-webkit-input-placeholder {
 color: #000 !important;
}
.imageBox input::-moz-placeholder, .imageBox textarea::-moz-placeholder {
 color: #000 !important;
}
.imageBox input:-ms-input-placeholder, .imageBox textarea:-ms-input-placeholder {
 color: #000 !important;
}
.imageBox input:-moz-placeholder, .imageBox textarea:-moz-placeholder { /* Firefox 18- */
 color: #000 !important;
}
.imageBox input {
	color: #000 !important;
}
.imageBox textarea {
	width: 100%;
	padding: 1%;
	min-height: 150px;
	font-size: 13px;
	color: #000;
}
.imageBox select {
	background-color: #fff;
	border: none;
	border-radius: 0;
	padding: 3px 3px;
	font-size: 18px;
	width: 100%;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	color: #000;
}
.imageBox .buttons {
	position: absolute;
	bottom: 18px;
	right: 26px;
	width: 100%;
}
.overlay-img {
	background-color: rgba(0,0,0,.5);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	z-index: 100000000;
}
.ui-dialog, .qq-alert-dialog-selector {
	z-index: 100000002 !important;
	border-radius: 8px !important;
	padding: 15px !important;
	border: 2px solid #e5b143 !important;
	background: #fefbe2 !important;
	width: 300px !important;
	left: 50% !important;
	margin-left: -150px !important;
}
.ui-dialog *:focus, .ui-dialog:focus {
	outline: none !important;
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.ui-dialog .ui-dialog-titlebar {
	display: none !important;
}
.ui-dialog .ui-dialog-content, .qq-dialog-message-selector {
	color: #e5b143 !important;
	height: auto !important;
	padding: 0px 5px !important;
	text-align: left !important;
	margin-bottom: 15px !important;
	color: #000 !important;
}
.ui-dialog .ui-dialog-buttonpane {
	background: none !important;
	border: none !important;
	padding: 0 !important;
	margin: 0 !important;
}
.ui-dialog .ui-dialog-buttonset, .qq-dialog-buttons {
	float: none !important;
	text-align: center !important;
	padding: 0px 5px !important;
}
.ui-dialog .ui-dialog-buttonset button, .qq-dialog-buttons button {
	margin: 0 5px !important;
	padding: 8px !important;
	font-size: small !important;
	font-family: Arial, Helvetica, sans-serif !important;
	font-weight: bold !important;
	width: 35% !important;
	outline: none !important;
}
.ui-dialog .ui-dialog-buttonset button:hover, .ui-dialog .ui-state-hover, .ui-dialog .ui-dialog-buttonset button:active, .ui-dialog .ui-dialog-buttonset button:focus {
	cursor: pointer !important;
	background-color: #e5b143 !important;
	background: #e5b143 !important;
	padding: 8px !important;
	margin-right: 0px !important;
	margin-top: 0px !important;
	font-weight: bold !important;
	font-size: small !important;
	font-family: Arial, Helvetica, sans-serif !important;
	border: 0px solid #000 !important;
	min-width: 36px !important;
	min-height: 27px !important;
	text-transform: uppercase !important;
	margin: 0 5px !important;
}
.ui-dialog {
	text-align: left !important;
}
.text-right {
	text-align: right !important;
}
.text-center {
	text-align: center !important;
}
.text-left {
	text-align: left !important;
}
.overlay, .overlay2 {
	background-color: #000;
	opacity: .5;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1000000 !important;
	position: fixed;
}
.clearlooks2 {
	z-index: 200000000 !important;
}
.defPop {
	width: 436px;
	position: absolute;
	top: 20px;
	left: 0;
	background-color: #fff;
	border: 3px solid #3a86b1;
	display: none;
	opacity: 0;
	z-index: 9999;
	padding: 10px;
	font-size: 12px;
	text-align: left;
}
.indicator_desc_close {
	float: right;

	max-height: 26px;
	overflow: hidden;
}
.defPop .indicator_desc_close{
	display: block;
	margin-top: -5px;
	margin-right: -5px;
}
.defPop .indicator_desc_close i{
	font-size: 26px;
}
.defPop p{
	margin: 0 !important;
}
.defPop p:first-of-type{
	margin-top: 0 !important;
}
.defPop img{
	margin-right: 25px;
	margin-top: -8px;
}
.topRight {
	display: block;
	position: absolute;
	top: -17px;
	right: -15px;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 10px;
}
.relWrap {
	position: relative;
	width: 226px;
	margin: 5px 0 0 220px;
}
.onlyRelWrap {
	position: relative;
}
.leftSide {
	position: absolute;
	left: 0;
	top: 0;
}
.rightSide {
	position: absolute;
	right: 0;
	top: 0;
}
.searchLabel {
	color: #e5b143;
	margin-right: 20px;
	font-size: 20px;
	margin: 5px 10px 5px 0;
	float: left;
	display: inline-block;
	width: auto;
}
#search-term { /*this code was written with IE8 in mind */
	background-color: #fff;
	height: 25px;
	line-height: 25px;
	border: none;
	border: 1px solid #727272;
	border-right: 1px solid #000;
	width: 209px;
	margin: 2px -3px 0 -4px;
	vertical-align: bottom;
	color: #727272 !important;
	padding-left: 5px;
	text-align: center;
	font-size: 14px;
	outline: none !important;
}
#search-term::-webkit-input-placeholder {
   color: #727272;
}

#search-term:-moz-placeholder { /* Firefox 18- */
   color: #727272;
}

#search-term::-moz-placeholder {  /* Firefox 19+ */
   color: #727272;
}

#search-term:-ms-input-placeholder {
   color: #727272;
}
/*other color
#search-term::-webkit-input-placeholder {
   color: #e8e8e8;
}

#search-term:-moz-placeholder {
   color: #e8e8e8;
}

#search-term::-moz-placeholder {
   color: #e8e8e8;
}

#search-term:-ms-input-placeholder {
   color: #e8e8e8;
}
*/
#search-button { /*this code was written with IE8 in mind */
	margin: 0px 0 0px 0;
	padding: 5px 1px 4px 0;
	width: 38px;
	height: 27px;
	line-height: 19px;
	color: #000;
	font-weight: bold;
	text-indent: 0;
	font-family: Arial, Helvetica, sans-serif;
	border: 1px solid #000;
	background: #e5b143;
	text-align: center;
	font-size: small;
}
a.cusIndBtns {
	display: inline-block;
	background: #FAE657;
	padding: 8px 10px 0;
	margin: 0 0 0 0;
	border-left: 1px solid #000;
	color: #000;
	font-weight: bold;
	font-size: 11px;
	font-family: Arial, Helvetica, sans-serif;
	height: 24px;
}
.auto-style385 {
	border-width: 0;
	background-color: #F0D07B;
	height: 28px !important;
}
.users-row .indMonthTable, .users-row .customProfessionCategory {
	padding: 1px;
}
.auto-style552 {
	cursor: pointer;
	border: 1px solid #FFFFFF;
	color: #FF0000;
	background-color: #41abb9 !important;
	font-size: xx-small;
	height: 28px !important;
}
.auto-styleAlert {
	cursor: pointer;
	border: 1px solid #FFFFFF;
	color: #FF0000;
	background-color: #ff797f;
	font-size: xx-small;
	height: 28px !important;
}
.manageusers.qmetrics .auto-styleAlert, .manageusers.qmetrics .auto-style552, .manageusers.qmetrics .auto-style517 {
	height: 28px !important;
}
.manageusers.qmetrics .auto-styleAlert, .manageusers.qmetrics .auto-style552, .manageusers.qmetrics .auto-style517 {
	height: 28px !important;
}
.auto-styleBlue {
	border: 1px solid #FFFFFF;
	color: #FF0000;
	background-color: #38959f;
	font-size: xx-small;
	height: 28px !important;
}
.auto-style517 {
	cursor: pointer;
	border: 1px solid #FFFFFF;
	color: #FF0000;
	background-color: #F9EBC6 !important;
	font-size: xx-small;
	height: 28px !important;
}
.dashboard .auto-styleAlert
{
	cursor: default;
}
#indicator_wrap {
	min-height: 1000px;
}
.loading_msg {
	text-align: center;
}
.loading_msg p {
	color: #D4A017;
	font-weight: bold;
	font-size: 14px;
	font-family: Arial, Helvetica, sans-serif;
	letter-spacing: 1px;
}
/* end dashboard styles

@media all and (max-width: 1000px) and (min-width: 700px) {
#content_wrapper #bucket_left p, #content_wrapper #bucket_right p {
	font-size: 9px;
	line-height: 110%;
}
#content_wrapper #bucket_right p {
	margin: 5px 0;
	font-size: 12px;
}
.home #the_footer p, .home #the_footer a {
	font-size: 8px !important;
}
}*/
.clearfix:after {
	clear: both;
	content: ".";
	display: block;
	height: 0;
	visibility: hidden;
}
.clearfix {
	display: inline-block;
}
.clearfix {
	display: block;
}
.newCust {
	color: #000;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-size: medium;
	text-transform: uppercase;
}
.linkColor {
	font-size: medium;
	color: #3A86B1;
}
/* manage users */
#category_key {
	float: right;
	margin-top: 9px;
	width: 225px;
	margin-left: 10px;
	padding-bottom: 10px;
}
#category_key li {
	margin: 0;
	padding: 0;
	float: left;
	width: 100%;
	color: #000;
	font-size: 14px;
	text-align: left;
}
#manage_users_key {
	display: none;
}
.manageusers .pagehead{
	display: inline-block;
	width: 30%;
}
.manageusers.larger_row_font .pagehead{
	width: 50%;
}
.manageusers #manage_users_key {
	display: inline-block;
	width: 70%;
	text-align: right;
	position: relative;
}
.inline-title{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding-bottom: 10px;
	font-size: 0;
}
#manage_users_key ul, #category_key ul {
	margin: 0;
	list-style: none;
	padding: 0;
}
#manage_users_key li {
	line-height: 18px;
	font-family: Tahoma, sans-serif;
	font-size: 16px;
	display: inline-block;
	letter-spacing: .3px;
	margin-left: 10px;
}
#manage_users_key li span {
	width: 17px;
	height: 17px;
	background-color: #41abb9;
	display: inline-block;
	margin-right: 5px;
	margin-top: -3.5px;
	vertical-align: middle;
	border: 1px solid #000;
	-webkit-box-shadow: inset 0px 0px 1px 1px rgba(255,255,255,0.75);
	-moz-box-shadow: inset 0px 0px 1px 1px rgba(255,255,255,0.75);
	box-shadow: inset 0px 0px 1px 1px rgba(255,255,255,0.75);
}
#manage_users_key li.c span {
	background-color: #e5ba05;
}
#manage_users_key li.three span {
	background-color: #38959F;
}
.manageusers .auto-styleAlert, .userlog .auto-styleAlert{
	background-color: #e5b143; /* 00a8a4 */
	height: 28px !important;
}
.manageusers .auto-style552, .userlog .auto-style552{
	background-color: #41abb9 !important;
	height: 28px !important;
}
.manageusers .auto-style517, .userlog .auto-style517 {
	height: 28px !important;
}
.userlog .auto-styleAlert, .userlog .auto-style552, .userlog .auto-style517{
	border-color: #000;
	cursor: default;
}
.userlog .auto-style517{
	background-color: #fff !important;
}
#userLogMainTable td{
	text-align: left;
	text-indent: 2px;
	padding: 2px !important;
}
.ind_division_1 {
	width: 52px;
}
.ind_division_2 {
	width: 43px;
}
.ind_division_3 {
	width: 70px;
}
.ind_division_4 {
	width: 334px;
	overflow: auto;
}
.ind_division_5 {
	width: 126px;
}
.ind_division_6 {
	width: 34px;
}
.ind_division_7 {
	width: 34px;
}
.ind_division_8 {
	width: 133px;
}
.ind_division_9 {
	width: 260px;
}
.ind_division_10 {
	width: 104px;
}
.daily {
	background-color: #ffcdcd !important;
}
.aind_cell_div {
	background-color: #fefbe2;
	height: 202px;
	border-right: 1px solid #000;
}
.aind_cell_div.oneSample{
	position: relative;
}
.aind_cell_div.oneSample:after{
	content: ' ';
	width: 196px;
	height: 144px;
	display: block;
	position: absolute;
	top: 29px;
	left: 0;
	background: #fefbe2;
	z-index: 5;
}
/* my account */

/* .myacccell {
	border-right: 1px solid #000;
} */
.myaccone {
	width: 175px;
}
.myacctwo {
	width: 175px;
}
.myaccthree {
	width: 350px;
}
.myaccfour {
	width: 160px;
}
.myaccfive {
	width: 80px;
}
.myaccsix {
	background-color: #e5b143;
	width: 180px;
}
.myacccell.myaccsix {
	height: 30px;
}
.myaccseven {
	width: 152px;
}
.myaccsix select, .division_profession select {
	width: 180px;
	background-color: #fff;
	font-size: 16px;
	border: none;
	color: #727272;
	-webkit-appearance: none;
}
#content_wrapper .aind_cell_div p {
	margin: 0 2px;
	text-align: left;
	color: #000;
	font-family: "Arial Narrow", Times New Roman, Arial, sans-serif;
	font-size: small;
	font-weight: bold;
}
#content_wrapper .aind_cell_div.ind_division_4 p, #content_wrapper .aind_cell_div.ind_division_4 p span strong, #content_wrapper .aind_cell_div.ind_division_4 p strong span, #content_wrapper .aind_cell_div.ind_division_4 p span, #content_wrapper .aind_cell_div.ind_division_4 p strong, #content_wrapper .aind_cell_div.ind_division_4 p *, #content_wrapper .aind_cell_div.ind_division_4 p * * {
	font-family: "Arial Narrow", Times New Roman, Arial, sans-serif;
	font-size: small;
	line-height: 110%;
}
.largeMainFont {
	font-size: 125%;
}
/* specific monthy styles */

.sm_division_1 {
	width: 68px;
}
.sm_division_2 {
	width: 35px;
}
.sm_division_3 {
	width: 119px;
}
.sm_division_4 {
	width: 601px;
}
.sm_division_5 {
	width: 137px;
}
.sm_division_6 {
	width: 151px;
}
.specificMonth #content_wrapper .dash_division {
	height: 22px;
	padding-top: 6px;
	font-weight: bold;
}
.specificMonth #content_wrapper .dash_division.sm_division_6 {
	background-color: #fff;
	padding-top: 0;
	height: 28px;
	line-height: 30px;
}
.specificMonth #content_wrapper .dash_division p {
	font-weight: bold;
	font-size: small;
	font-family: "Arial Narrow", Times New Roman, Arial, sans-serif;
	text-indent: 5px;
	text-align: left;
}
.specificMonth #content_wrapper .dash_division .defPop p, .hover-popup p {
	text-indent: 0;
	text-align: left;
}
.specificMonth #content_wrapper .dash_division.sm_division_1 p, .specificMonth #content_wrapper .dash_division.sm_division_2 p {
	text-indent: 0;
	text-align: center;
}
.auto-style514 {
	text-align: center;
	background-color: #F9EBC6;
	font-family: "Arial Narrow";
	font-size: small;
	font-weight: normal;
	color: #000000;
}
.rowWrap {
	width: 1075px;
	min-height: 28px;
	overflow: hidden;
	height: auto;
}
.rowWrap.Long {
	width: 966px;
}
.rowHeader {
	float: left;
	background: #e5b143;
	border-right: 1px solid #000;
	color: #000;
	font-family: Arial, Helvetica, sans-serif;
	text-align: center;
	font-weight: bold;
	padding: 6px 0 0 0;
	height: 22px;
	font-size: 13px;
	border-bottom: 1px solid #000;
	text-align: center;
}
.rowDivision {
	float: left;
	background-color: #F9EBC6;
	text-align: left;
	border-right: 1px solid #000;
	min-height: 256px;
	padding: 0;
}
.rowDivision.four, .rowDivision.six {
	background-color: #fff;
}
.rowDivision td {
	text-align: center;
}
.one {
	width: 105px;
}
.one_n_half {
	width: 120px;
}
.two {
	width: 159px;
}
.three {
	width: 204px;
}
.four {
	width: 145px;
}
.four_five {
	width: 150px;
}
.five {
	width: 150px;
}
.six {
	width: 100px;
	text-align: center;
}
.newCust {
	color: #000;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-size: medium;
	text-transform: uppercase;
	font-weight: bold;
}
.customfile-wrap {
	display: inline-block;
	position: relative;
	width: 225px;
	/*top: -11px;*/
	margin-left: 4px;
}
.customfile-filename, .customfile-upload {
	margin: 0;
	padding: 0;
}
.customfile-filename {
	height: 30px;
	width: 146px;
	font-size: 18px;
	background-color: #fff;
	text-align: left;
	color: #727272;
	padding-left: 10px;
	border: none;
	border-width: 0px;
	box-shadow: inset 0 1px 2px rgba(0,0,0,.2);
	position: absolute;
	top: 0;
	left: 0;
}
.customfile-filename:focus {
	outline: none;
}
.customfile-upload {
	line-height: 32px;
	height: 28px;
	text-transform: uppercase;
	display: inline-block;
	padding: 0 8px;
	border: 1px solid #cccccc;
	color: #6B6863;
	background: #ddd;
	margin-left: -1px; /* align with input */
	cursor: pointer;
	background: #f0f0f0;
	background: -moz-linear-gradient(top, #f0f0f0 0%, #f0f0f0 100%);
	background: -webkit-linear-gradient(top, #f0f0f0 0%, #f0f0f0 100%);
	background: -o-linear-gradient(top, #f0f0f0 0%, #f0f0f0 100%);
	background: -ms-linear-gradient(top, #f0f0f0 0%, #f0f0f0 100%);
	background: linear-gradient(to bottom, #f0f0f0 0%, #f0f0f0 100%);
	font-size: 12px;
	background: #ddd url(../images/browse.png) center center no-repeat;
	width: 52px;
	position: absolute;
	top: 0;
	right: 0;
}
.customfile-upload:hover {
	background: #fafafa url(../images/browse.png) center center no-repeat;
	box-shadow: 0 0 2px rgba(0,0,0,.2);
}
.customfile-upload::-moz-focus-inner { /* Fix firefox padding */
 padding: 0;
border: 0
}
#bucket_right .makeItWhite {
	color: #fff;
	text-decoration: none;
}
#bucket_left p a {
	color: #000;
	text-decoration: none;
}
#content_wrapper p.pagination {
	float: right;
	color: #D4A017;
	font-size: 20px;
	padding: 0 6px;
	margin: 3px 50px 0 0;
	width: 270px;
}
#content_wrapper p.pagination a {
	color: #D4A017;
	font-size: 20px;
	padding: 0 0px;
	margin: 0;
}
.search_msg {
	margin-left: 3px;
	font-size: 16px;
	color: #fff;
}
input[type="text"] {
	-webkit-appearance: none;
	border-radius: 0;
	border: 0px solid #ccc;
}
.dash_cell table {
	width: 34px;
	height: 29px;
}
.faq_highlight {
	font-weight: bold;
	font-size: 135%;
	font-family: "Lato", Tahoma, Geneva, sans-serif;
	display: inline-block;
	width: 17px;
}
.faq_highlight.dark_yellow_highlight {
	font-family: "Lato", Geneva, sans-serif;
	font-size: 125%;
}
#content_wrapper #tabs p {
	margin: 8px 0;
}
.ui-tabs-nav li a {
	font-weight: bold;
}
.faq .ui-tabs .ui-tabs-nav li a {
	padding: 0.5em 0.7em;
}
#RightSideSealsTop{
	float: right;
	position: absolute;
	right: 0;
	top: 20px;
}
#CenterSealsTop {
	position: absolute;
	width: 207px;
	height: 63px;
	right: 516px;
	bottom: 130px;
}
#CenterSealsTop *{
	line-height: 100% !important;
}
.siteSealLeftTop {
	position: absolute;
	top: 0;
	left: 0;
}
.siteSealLeftBottom {
	position: absolute;
	bottom: 0;
	left: 0;
}
.siteSealRightTop {
	position: absolute;
	bottom: 0;
	right: 0;
}
#siteseal {
	display: inline-block;
	width: 104.5px;
	overflow: hidden;
	border: 1px solid #e5b143;
	height: 26px;
}
.yellow_border {
	border: 1px solid #e5b143;
}
.floatImgRight {
	float: right;
	margin-left: 5px;
}
.yellow_border.floatImgRight {
	margin-left: 3px;
}
.pull-right {
	float: right !important;
}
.pull-left{
	float: left !important;
}
.updateAllUsers {
	margin: 5px 5px 0 0;
}

/* CSS3 PULSATE EFFECT */

@-webkit-keyframes glow {
 0% {
 -webkit-box-shadow: 0 0 25px #D4A017;
}
 100% {
 -webkit-box-shadow: 0 0 25px #D4A017, 0 0 65px #D4A017;
}
}
@-moz-keyframes glow {
 0% {
 -moz-box-shadow: 0 0 25px #D4A017;
}
 100% {
 -moz-box-shadow: 0 0 25px #D4A017, 0 0 65px #D4A017;
}
}
@-o-keyframes glow {
 0% {
 box-shadow: 0 0 25px #D4A017;
}
 100% {
 box-shadow: 0 0 25px #D4A017, 0 0 65px #D4A017;
}
}
@keyframes glow {
 0% {
 box-shadow: 0 0 25px #D4A017;
}
 100% {
 box-shadow: 0 0 25px #D4A017, 0 0 65px #D4A017;
}
}
.currentMonth {
	-webkit-animation-name: glow;
	-webkit-animation-duration: 1s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	-webkit-animation-timing-function: ease-in-out;
	-moz-animation-name: glow;
	-moz-animation-duration: 1s;
	-moz-animation-iteration-count: infinite;
	-moz-animation-direction: alternate;
	-moz-animation-timing-function: ease-in-out;
	-o-animation-name: glow;
	-o-animation-duration: 1s;
	-o-animation-iteration-count: infinite;
	-o-animation-direction: alternate;
	-o-animation-timing-function: ease-in-out;
	animation-name: glow;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: ease-in-out;
}
/* CSS3 PULSATE EFFECT END */
.tealBorder, .currentMonth.tealBorder {
	border-right: 2px solid #ff0000 !important;
}
.tutorials_on p.tutorialToggleWrap {
	display: block;
}
p.tutorialToggleWrap {
	margin-right: 90px;
	font-size: 14px;
	margin-top: 5px;
	text-transform: uppercase;
	width: 125px;
	background: url(../images/cloud.png) 60px 0px no-repeat;
	padding: 6px;
	text-align: left;
	position: absolute;
	top: -11px;
	left: 0px;
	display: none;
}
p.tutorialToggleWrap a {
	color: #000 !important;
}
p.tutorialToggleWrap a span {
	color: #000;
	font-weight: bold;
	text-decoration: underline;
	padding-left: 13px;
}
#footer p {
	-webkit-text-size-adjust: 100%;
}
#footer p.tutorialToggleWrap a span.off {
	padding-left: 11px;
}
.top_block {
	-webkit-text-size-adjust: 100%;
}
.top_block a, #bucket_left a {
	color: #000;
	text-decoration: none;
}
.top_block strong, #bucket_left strong {
	font-weight: normal;
}
p.top_block:empty{
	margin: 0 !important;
}
.r1 {
	background-color: #ff0000 !important;
}
.r2 {
	background-color: #ff413c !important;
}
.r3 {
	background-color: #ff605d !important;
}
.r4 {
	background-color: #ff807e !important;
}
.r5 {
	background-color: #ff9f9e !important;
}
.r6 {
	background-color: #ffb3b2 !important;
}
.r7 {
	background-color: #ffc9c8 !important;
}
.pop_up a:not(.ptbutton) {
	color: #D4A017 !important;
	text-decoration: none;
}
#footerWaterMark {
	display: none;
}

@media print {
/* PAGE BREAK */
.page-break {
	page-break-after: always;
}
.auto-style475, .auto-style476, .auto-style477, .auto-style478, .auto-style353 {
	border: 2px solid #000 !important;
}
.table_all td.no-border {
	border: 0 none !important;
}
.table_all td.no-border-top {
	border-top: 0 none !important;
}
.table_all td.no-border-right {
	border-right: 0 none !important;
}
.table_all td.no-border-left {
	border-left: 0 none !important;
}
.table_all td.no-border-bottom {
	border-bottom: 0 none !important;
}
.assignedinds #footer, .assignedinds #header {
	display: none;
}
#footerWaterMark {
	display: block;
	position: fixed;
	bottom: 0px;
	right: 0;
	width: 1000px;
	height: 100px;
	z-index: 5;
}
body {
	margin-bottom: 2in;
}
}
.cms2567 thead {
	display: none;
}
tfoot {
	display: none;
}

@media print {
thead {
	display: table-header-group;
}
tfoot {
	display: table-footer-group;
}
}
/*
@page {

	margin-bottom:2in;
	size:8.5in 11in;

}
*/
.assigned_indicators_detail {
	margin-bottom: 10px;
}
.assignedinds .aind_cell_div.ind_division_9 {
	background-color: #fff !important;
}
.q-audit .dash_division.division_17.small {
	font-size: 16px;
	display: table;
	text-align: left;
	text-indent: 5px;
}
.q-audit .dash_division.division_17.small p {
	display: table-cell;
	vertical-align: middle;
	color: #000;
}
.hideOnDesktop {
	display: none;
}
.normal_weight {
	font-weight: normal !important;
}
#signature_wrapper {
	width: 900px;
	background-color: #fff;
	border: 1px solid #e5b143;
	overflow: hidden;
}
.cms2567 #signature_wrapper {
	border: none;
	display: none;
}
#superNavLeft {
	position: absolute;
	top: 20px;
	left: 0;
	text-align: left;
}
#superNav {
	position: absolute;
	top: 36px;
	right: 0;
	text-align: right;
	max-width: 416px;
}
#superNav p, #superNav p a, #superNavLeft p, #superNavLeft p a, .header_text_style {
	text-decoration: none !important;
	font-family: "Lato", Arial, Helvetica, sans-serif !important;
	color: #000 !important;
	font-size: 22px !important;
}

#superNav a, /*#superNav img,*/ .superNav a/*, .superNav img*/ {
	margin-bottom: 0 !important;
	vertical-align: text-bottom;
	text-decoration: none !important;
}
#superLoginWrap i{
	vertical-align: top;
	display: inline-block;
	font-size: 18px;
	overflow: hidden;
	max-height: 18px;
	margin-right: 8px;
}
.pop_up .superNav a{
	vertical-align: text-top !important;
}
.signal-indicator-green{
	color: #4D8033;
}
.signal-indicator-red{
	color: #f11212;
}
h1 a {
	text-decoration: none;
}
.h1_wrap {
	overflow: hidden;
	height: 32px;
	margin-bottom: 20px;
	width: 115px;
	border-bottom: 1px solid #e5b143;
}
.yes, .no {
	cursor: pointer;
}
.yes + .yes:nth-last-child(-n+2), .no + .no:nth-last-child(-n+2)
{
	border-bottom: 0 !important;
}
#searchFormWrap {
	float: right;
	width: 254px;
	margin-right: 18px;
}
#searchFormWrap div {
	padding-top: 4px;
}
.dashboard .dash_cell_header {
	border-top: 1px solid #000000 !important;
}
.dashboard .indMonthTable td
{
	text-align: center;
	vertical-align: middle;
}
.dashboard .indMonthTable td a
{
	text-decoration: none !important;
	color: #000;
	font-size: 14px;
}
.promotional_box {
	width: 400px;
	margin: 50px auto 0;
	text-align: center;
	font-size: 30px;
	color: #000;
	font-family: Arial, Helvetica, sans-serif;
	background: #FAE657;
	background: -webkit-gradient(linear, 0 0, 0 bottom, from(#FAE657), to(#B68C07));
	background: -webkit-linear-gradient(#FAE657, #B68C07);
	background: -moz-linear-gradient(#FAE657, #B68C07);
	background: -ms-linear-gradient(#FAE657, #B68C07);
	background: -o-linear-gradient(#FAE657, #B68C07);
	background: linear-gradient(#FAE657, #B68C07);
	-pie-background: linear-gradient(#FAE657, #B68C07);
 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FAE657', endColorstr='#B68C07');
	cursor: pointer;
	padding: 15px 0;
}
.promotional_box span {
	font-size: 18px;
}
.big_yellow_link {
	color: #000;
	font-size: 26px;
}
 .#3A86B1 {
color:#3A86B1 !important;
}
.coming_soon img {
	float: left;
	margin: 0 15px 0 0;
}
#content_wrapper .partner{
	float: left;
	text-align: center;
	margin-right: 2%;
	width: 30.9% !important;
	padding: 15px 5px;
	min-height: 52px;
}
#content_wrapper .partner:last-child{
	margin-right: 0 !important;
}
.by_two {
	float: left;
	width: 50%;
	padding-top: 20px;
}
.make_it_blue {
	color: #3A86B1 !important;
}
.make_it_bluer {
	color: #3A86B1 !important;
	font-size: 8px;
	font-family: Arial, Helvetica, sans-serif;
}
.hasDatepicker {
	font-weight: bold;
}
.hasDatepicker, .make_it_gold {
	color: #D4A017 !important;
}
.q_risk .dash_cell.division_1, .q_risk .dash_cell.division_2, .q_risk .dash_cell.division_3, .q_risk .dash_cell.division_4 {
	background-color: #ffcdcd !important;
}
ol {
	margin-left: 17px;
}
.ui-icon-gripsmall-diagonal-se {
	background-position: -64px -224px;
	cursor: se-resize;
}
.ui-icon, .ui-widget-content .ui-icon {
	background-image: url("jquery_ui/images/ui-icons_222222_256x240.png");
}
.pop_up .assignedinds .ui-icon {
	background-color: #D4A017;
	bottom: 0;
	height: 16px;
	position: absolute;
	right: 0;
	width: 16px;
}
.manageusers .searchLabel {
	margin: 0;
	padding: 3px 0 0 0;
	text-align: center;
}
.page_dep_toggle {
	display: inline-block;
 	margin-left:30px:;
	font-size: 33px;
	height: 33px;
	max-height: 33px;
	overflow: hidden !important;
}
.page_dep_toggle:after{
	max-height: 33px;
}
.page_dep_toggle_holder {
	margin-left: 360px;
	margin-top: -35px;
}
.dep_toggle {
	color: #b5b5b5;
}
.dep_toggle.pointer {
	color: #e5b143;
}
.pointer {
	cursor: pointer;
}
.right_img {
	position: absolute;
	right: -2px;
	top: 0;
}
.highlighted, .q_risk .dash_cell.division_1.highlighted, .q_risk .dash_cell.division_2.highlighted, .q_risk .dash_cell.division_3.highlighted, .q_risk .dash_cell.division_4.highlighted {
	background-color: #F0D07B !important;
}
.dashboardSearch{
	background-color: #a4ff9e !important;
}
#setDates {
	position: absolute;
	top: 0px;
	margin: 0;
	right: 0;
	padding: 4px 8px 3px;
	height: 25px;
}
.page_dep_toggle img {
	margin-bottom: -4px;
}
.next_dep {
	margin-left: 10px;
}
.selectricWrapper {
	float: right;
	position: relative;
	margin: 0 0 10px;
	width: 300px;
	cursor: pointer;
}
.selectricResponsive {
	width: 100%;
}
.selectric {
	border: 1px solid #3a86b1;
	background: #e5b143;
	position: relative;
	border-radius: 2px;
}
#content_wrapper .by_three .selectric p {
	color: #fff;
	font-family: Arial Unicode MS, Arial;
}
.selectric .label {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	margin: 0 30px 0 0;
	padding: 6px;
	font-size: 12px;
	line-height: 18px;
	color: #444;
	min-height: 18px;
}
.selectric .button {
	display: block;
	background-color: #e5b143;
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
	height: 30px;
	color: #e5b143;
	text-align: center;
	font: 0/0 a;
 *font: 20px/30px Arial Unicode MS, Arial;
}
.selectric .button:after {
	content: " ";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	width: 0;
	height: 0;
	border: 8px solid transparent;
	border-top-color: white;
	border-bottom: none;
}
.selectricOpen {
	z-index: 9999;
}
.selectricOpen .selectric {
	z-index: 9999;
}
.selectricOpen .selectricItems {
	display: block;
}
.selectricDisabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-touch-callout: none;
	user-select: none;
}
.selectricHideSelect {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
}
.selectricHideSelect select {
	position: absolute;
	left: -100%;
	display: none;
}
.selectricInput {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
 *font: 0/0 a !important;
	background: none !important;
}
.selectricTempShow {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}
.selectricItems {
	display: none;
	position: absolute;
	overflow: auto;
	top: 100%;
	left: 0;
	background: #000;
	border: 1px solid #e5b143;
	z-index: 9998;
	box-shadow: 0 0 10px -6px;
}
.selectricItems ul, .selectricItems li {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 22px;
	line-height: 22px;
	min-height: 22px;
}
.selectricItems li {
	display: block;
	padding: 5px;
	border-bottom: 1px solid #e5b143;
	color: #e5b143;
	cursor: pointer;
}
.selectricItems li.selected {
	background: #3A86B1;
	color: #fff;
}
.selectricItems li:hover {
	background: #3A86B1;
	color: #000;
}
.selectricItems li.disabled {
	background: #F5F5F5;
	color: #BBB;
	border-top-color: #FAFAFA;
	cursor: default;
}
#content_wrapper .selectric p, #content_wrapper .selectricItems ul {
	margin: 0;
}
.remove {
	float: right;
	background: url(../images/red-x-out-icon.png) center center no-repeat;
	width: 16px;
	height: 16px;
	display: inline-block;
	padding-top: 4px;
}
.ceu_img_wrap {
	width: 25%;
	line-height: 110px;
	min-height: 130px;
	float: left;
	text-align: center;
}
#mag_icon img {
	margin-left: 20px;
	margin-bottom: -3px;
}
.footer_bucket {
	float: left;
	width: 25%;
	text-align: left !important;
}
#footer .footer_bucket a, #footer .footer_bucket p {
	color: #000;
	text-decoration: none;
}
#footer .footer_bucket p {
	line-height: 145%;
}
#footer .footer_bucket h2 {
	margin-bottom: 5px;
}
.lato {
	font-family: "Lato", sans-serif;
}
.home_top {
	background: url('../images/snfqapi_home1.jpg') center center no-repeat;
	width: 1200px;
	height: 704px;
	position: relative;
}
.home_top_box {
	width: 895px;
	margin: 0 auto;
	height: 214px;
	position: absolute;
	left: 134px;
	bottom: 50px;
	opacity: .9;
}
.home_top_box_trial {
	height: 224px;
	width: 730px;
	background: url('../images/home_trial.jpg') center center no-repeat;
}
#home_top_trial {
	width: 100%;
	background-color: #e5b143;
	font-family: "Lato", sans-serif;
}
#home_top_trial a {
	text-decoration: none;
	color: #000;
}
#home_top_trial p strong {
	font-size: 28px;
}
#home_top_trial p {
	margin: 0px;
	text-align: center;
	color: #000;
	font-size: 20px;
	padding: 5px 0px;
}
#home_top_trial_text ul {
	list-style-image: url('../images/list_check.png');
	list-style-position: outside;
	padding: 5px 0 0 0;
}
#home_top_trial_text li {
	float: left;
	margin: 6px 30px 0 125px;
	color: #fff;
	font-size: 23px;
	width: 255px;
	line-height: 100%;
}
#home_top_trial_text {
	background-color: #3a86b1;
	height: 142px;
	overflow: hidden;
}
.home_about_hdr {
	height: 86px;
	width: 100%;
	background-color: #e5b143;
}
.home_about_hdr h1, .home_how_hdr h1 {
	font-family: "Lato", sans-serif;
	font-weight: bold;
	color: #fff;
	text-align: center;
	line-height: 90px;
}
#about_us_section p {
	margin: 5px 0px;
}
.about_us_section {
	width: 100%;
	overflow: hidden;
	position: relative;
	padding: 20px 0px 20px 0px;
}
.about_us_qrc {
	background: url('../images/about_us_sprite.png') -2px 0px no-repeat;
}
.about_us_rc {
	background: url('../images/about_us_sprite.png') -100px 0px no-repeat;
}
.about_us_fsrs {
	background: url('../images/about_us_sprite.png') -196px 0px no-repeat;
}
.about_us_la {
	background: url('../images/about_us_sprite.png') -294px 0px no-repeat;
}
.about_us_rr {
	background: url('../images/about_us_sprite.png') -392px 0px no-repeat;
}
.about_us_rs {
	background: url('../images/about_us_sprite.png') -488px 0px no-repeat;
}
.about_us_opt {
	float: left;
	width: 240px;
	height: 300px;
	margin: 20px 80px;
}
.about_us_opt h1 {
	font-family: "Lato", sans-serif;
	font-weight: bold;
	color: #3a86b1;
	font-size: 22px;
	text-align: center;
	line-height: 120%;
	margin: 10px 0;
}
.about_us_opt p {
	color: #616161;
	text-align: center;
	font-size: 16px;
}
.about_us_opt div {
	width: 100%;
	min-height: 115px;
	margin: 0 auto;
}
.home_how {
	position: relative;
}
.home_how_main {
	position: relative;
	background: url('../images/how-it-works.jpg') top center no-repeat;
	width: 1200px;
	height: 708px;
}
.home_how_hdr {
	height: 88px;
	width: 100%;
	background-color: #e5b143;
}
.home_how_box {
	width: 520px;
	opacity: .9;
	background-color: #4f92bd;
	position: absolute;
	bottom: -18px;
	left: 76px;
}
.home_how_box p {
	text-align: left;
	line-height: 150%;
	color: #fff;
	font-size: 26px;
	padding: 15px;
	margin: 0 !important;
}
.hiw_section {
	height: 460px;
	width: 100%;
	overflow: hidden;
	position: relative;
}
.hiw_ai {
	background: url('images/hiw_sprite.png') 0px 0px no-repeat;
}
.hiw_sr {
	background: url('../images/hiw_sprite.png') -210px 0px no-repeat;
}
.hiw_sd {
	background: url('../images/hiw_sprite.png') -426px 0px no-repeat;
}
.hiw_opt {
	float: left;
	width: 308px;
	height: 278px;
	margin: 45px 46px 0px;
}
.hiw_opt h1 {
	font-family: "Lato", sans-serif;
	font-weight: bold;
	color: #3a86b1;
	font-size: 22px;
	text-align: center;
	line-height: 130%;
}
.hiw_opt p {
	color: #616161;
	text-align: center;
	font-size: 16px;
}
.hiw_opt div {
	width: 210px;
	height: 210px;
	margin: 10px auto;
}
.hiw_opt_sd {
	width: 250px;
}
#hiw_bottom_box {
	background-color: #5395be;
}
#hiw_bottom_box p {
	margin: 1px 0px;
	text-align: justify;
	color: #fff;
	font-size: 24px;
	padding: 10px 20px;
	line-height: 150%;
}
#home_bottom_trial, #footer_copyright {
	min-height: 28px;
	width: 100%;
	background-color: #e5b143;
}
#footer_copyright {
	overflow: hidden;
}
#home_bottom_trial a, #home_footer a {
	text-decoration: none;
	color: #000;
}
#home_bottom_trial p {
	text-align: center;
	color: #000;
	font-size: 16px;
	padding: 5px 0px;
}
#home_footer p {
	margin: 0px;
	text-transform: uppercase;
}
#home_footer {
	height: 130px;
	overflow: hidden;
	color: #505050;
}
#home_footer ul {
	list-style-type: none;
	list-style: none;
	width: 100%;
}
#footer_copyright {
	height: 30px;
	text-align: center;
	padding: 0;
}
#footer_copyright h4 {
	line-height: 32px;
	height: 30px;
	color: #fff;
	text-transform: uppercase;
}
.home_footer_rows, .home_footer_row1, .home_footer_row2, .home_footer_row3, .home_footer_row4, .home_footer_row5, .home_footer_row6 {
	float: left;
	margin: 15px 0px;
	padding-left: 11px;
}
.home_footer_rows p, .home_footer_row1 p, .home_footer_row2 p, .home_footer_row3 p, .home_footer_row4 p, .home_footer_row5 p, .home_footer_row6 p {
	text-align: left;
	line-height: 150% !important;
}
.home_footer_row1 {
	width: 192px !important;
	padding-left: 17px !important;
}
.home_footer_row2 {
	width: 177px !important;
}
.home_footer_row3 {
	width: 189px !important;
}
.home_footer_row4 {
	width: 184px !important;
}
.home_footer_row5 {
	width: 200px !important;
}
.home_footer_row6 {
	width: 183px !important;
}
/*
.home_footer_rows:nth-child(1) {
    width: 199px !important;
	margin-left:13px;
}

.home_footer_rows:nth-child(5) {
	margin-right:0;
	width:199px !important;
}

.home_footer_rows:last-child {
	margin-right:0;
	text-align:left !important;
	width:188px !important;
}
 */


#home_footer_social li {
	float: right;
	width: 59px;
	height: 57px;
}
#home_footer_social a {
	font-size: 45px !important;
	color: #5395be;
}
.home_footer_fb {
	background: url('../images/social_sprite.png') 0px 0px no-repeat;
}
.home_footer_tw {
	background: url('../images/social_sprite.png') -49px 0px no-repeat;
}
.home_footer_yt {
	background: url('../images/social_sprite.png') -98px 0px no-repeat;
}
.home_footer_em {
	background: url('../images/social_sprite.png') -148px 0px no-repeat;
}
.suggest_button {
	border-style: none;
	border-color: inherit;
	width: 113px;
	padding: 0;
	color: #fff;
	height: 45px;
	background-color: #3A86B1;
	border-width: 0;
	font-size: 18px;
	margin-left: 0;
}
.set_width {
	box-sizing: border-box;
	width: 1198px;
	margin-left: 1px;
	border-top: 0px !important;
	height: 28px;
	background-color: #fcd984 !important;
}
.set_width_daily {
	box-sizing: border-box;
	width: 1199px;
	margin-left: 1px;
	border-top: 0px !important;
	height: 28px;
	background-color: #fcd984 !important;
}
.pagehead {
	font-size: 30px;
	font-family: "Lato", Tahoma, Geneva, sans-serif;
	font-weight: 400;
	color: #000000;
	text-decoration: none;
}
.sechead {
	margin: 2px 0 10px 14px;
}

::-webkit-input-placeholder {
 color: #e8e8e8;
}
::-moz-placeholder { /* Firefox 19+ */
 color: #e8e8e8;
}
:-ms-input-placeholder {
 color: #e8e8e8;
}
:-moz-placeholder { /* Firefox 18- */
 color: #e8e8e8;
}

/* Dashboard Selects*/

<style>  .select-div {
 position: absolute;
 background-color: #E5B143;
 color: #000000 !important;
 height: 32px; /*28px*/
 line-height: 28px;
 overflow: hidden;
 margin: 0px;
 padding: 0px;
 position: relative;
 width: 138px;
 z-index: 100;
}
.select-default {
	background-color: #E5B143;
	display: block;
	left: 4px;
	position: absolute;
	text-align: left;
	top: 8px;
	z-index: 100;
}
.select-div select {
	display: block;
	font-size: 13px;
	height: 100%;
	left: -2px;
	margin: 0;
	opacity: 0;
	padding: 0;
	position: absolute;
	top: 0px; /**/
	-webkit-appearance: listitem;
	width: 141px;
	z-index: 101;
}
.select-div select:focus {
	outline: none;
}
.select-default, .select-div option {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
}
.select-triangle {
	color: #000000;
	font-size: 16px;
	line-height: 16px;
	position: absolute;
	right: 6px;
	top: 10px;
}
.select-drop-down {
	border-top: 1px #cccccc solid;
	border-bottom: 1px #cccccc solid !important;
}
.select-drop-down option {
	background-color: #3a86b1;
	border: 1px #cccccc solid;
	border-bottom: none;
	color: white;
	font-size: 12px;
	padding: 10px;
	width: 100%;
}
#header img {
	margin-right: -5px;
}
.home_top {
	background: url('../images/snfqapi_home1.jpg') center center no-repeat;
	width: 100%;
	height: 652px;
	position: relative;
}
#five-star-icon, #resident_satisfaction-icon {
	color: #d49b00 !important;
	text-align: center;
	height: 100px;
	padding: 5px 0;
	font-size: 116px;
}
#nav ul li a {
	width: 171.4px;
}
#nav ul li ul li a, .dashboard #nav ul li.dashboard ul li a, .home #nav ul li.home ul li a, .library #nav ul li.library ul li a, .indicators #nav ul li.indicators ul li a, .login #nav ul li.login ul li a {
	width: 170px;
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;
}
.hover-popup *, .hover-popup * * {
	font-weight: bold;
	font-family: Arial, Helvetica, sans-serif;
	text-transform: uppercase;
}
#changeDateDialog > #changeDatePicker{
	width: 269.953px;
}
.manageusers .indicatorDatePicker{
	text-decoration: none;
}
.manageusers .indicatorDatePicker, .manageusers .indicatorDatePicker:active, .manageusers .indicatorDatePicker:focus, .manageusers .indicatorDatePicker:hover{
	outline: 0 !important;
}
/*body.dashboard select{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0 !important;
	text-indent: 5px;
}*/

/* Support */

.support {
	font-family: "arial";
	font-size: 16px ! important;
	color: #000000;
"
}
.support-head {
	font-family: "Lato", arial, helvetica;
	font-size: 18px;
	font-weight: 400;
	padding-top: 14px;
	color: #3a86b1;
}
.gallery .lSSlideOuter, .gallery .inline-block-49 {
	margin-right: .5%;
}
.gallery .samplePhotoInfo {
	margin-left: .5%;
}
.gallery .lSSlideOuter, .gallery .samplePhotoInfo, .inline-block-49 {
	height: 100%;
	height: calc(100% - 35px);
	width: 49%;
	position: relative;
	display: inline-block;
	vertical-align: top;
}
.gallery .gallery-slider {
	text-align: center !important;
}
.gallery .inline-block-49 img {
	max-width: 100%;
	width: auto;
	height: auto;
	max-height: 100%;
}
.gallery .samplePhotoInfo {
	text-align: left;
	font-size: 125%;
}
.gallery .lSSlideWrapper {
	position: static;
	height: 100% !important
}
.gallery .gallery-slider {
	height: 100% !important;
}
.gallery .gallery-slider li {
	height: 100% !important;
}
.gallery .gallery-slider img {
	width: auto;
	height: auto;
	max-width: 85%;
	max-height: 85%;
}
.gallery .slideSpecificInfo {
	padding-bottom: 35px !important;
}
.sticky-wrapper{
	margin-bottom: 4px;
}
.sticky_inside{
  top: 47px;
  width: 1200px;
  overflow: hidden;
}
.sticky_inside button {
	padding: 6px 8px;
	margin: 0px 0;
	font-family: Arial, Helvetica, sans-serif;
	background: #3A86B1;
	color:#fff;
	text-transform:uppercase;
	font-size:16px;
	font-weight:normal;
	float:right;
	margin-right:10px;
	line-height: 16px;
	border: 1px solid #34789f;
}
.sticky_inside button:hover{
	background: #34789f;
}
#logo_content_area {
	height: 600px;
	padding: 10px;
	background-color: #fff;
	overflow: auto;
}
#logo_content_area p { color:#000; }
.special-selectric .remove {
	display: none !important;
	text-indent: 9999;
}
.special-selectric .selectricWrapper{
    width: 230px !important;
}
.special-selectric .selectric{
    border: none !important;
    border-radius: 0 !important;
}
.special-selectric .selectric p{
    font-size: 16px !important;
    font-weight: 700 !important;
    padding: 10px !important;
}
.special-selectric .selectric, .special-selectric .selectric .button{
    background-color: #3a86b1 !important;
}
.special-selectric .selectric .button:after{
    top: 8px;
}
.special-selectric .selectricItems{
    border: 0 !important;
}
.special-selectric .selectricItems li{
    color: #fff !important;
    font-size: 14px !important;
    background-color: #3a86b1 !important;
    margin: 0 !important;
    padding: 10px !important;
    font-weight: 700 !important;
    border-bottom: 1px solid #ccc !important;
    border-left: 0px solid #ccc !important;
    line-height: 22px !important;
}
.special-selectric .selectricItems li:hover, .special-selectric .selectricItems li:active, .special-selectric .selectricItems li:focus, .special-selectric .selectricItems li:active{
    background-color: #000 !important;
}
.custom-select:focus, .custom-select:active, .custom-select:visited{
	outline: none !important;
}
.custom-select{
	text-overflow: '';
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-image: url('../images/Down-Arrow-Gray.png');
	background-position: 96% 60%;
	background-color: #fff;
	background-repeat: no-repeat;
	background-size: 15px 15px;
	border: none !important;
	height: 100%;
	width: 100%;
	padding: 3px 25px 3px 4px;
	border-radius: 0 !important;
	outline: none !important;
	font-size: 14px;
	text-indent: 0.01px;
}
.custom-select::-ms-expand{
	display: none !important;
}
.usr_prof.custom-select{
	background-image: url('../images/Down-Arrow-Gray.png');
}
.custom-input{
	padding-right: 25px; text-indent: 5px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
#qapi_library thead th{
	padding: 0;
	text-align: center;
}
.border-left-1px{
	border-left: 1px solid #000 !important;
}
.no-border{
	border: none !important;
	border-left-width: 0px !important; border-right-width: 0px !important; border-top-width: 0px !important; border-bottom-width: 0px !important;
}
.hover-popup *{
	font-weight: bold;
}
.hover-popup .pop-content{
	display: block;
}
.file-picker-form{
	text-align:left;
	margin:0 auto;
	width:530px;
}
.file-picker-form label{
	vertical-align: text-top;
}
.file-picker-form .customfile-wrap{
	vertical-align: super;
}


/*======================================
  Selectric v1.9.3
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 1px solid #DDD;
  background: #F8F8F8;
  position: relative;
}
.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 0 10px;
  font-size: 12px;
}
.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  color: #BBB;
}
.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #BBB;
  border-bottom: none;
}

.selectric-hover .selectric {
  border-color: #C4C4C4;
}
.selectric-hover .selectric .button {
  color: #A2A2A2;
}
.selectric-hover .selectric .button:after {
  border-top-color: #A2A2A2;
}
.selectric-open .selectric {
  border-color: #C4C4C4;
}
.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}
.selectric-hide-select select {
  position: absolute;
  left: -100%;
  display: none;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F8F8F8;
  border: 1px solid #C4C4C4;
  box-shadow: 0 0 10px -6px;
}
.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}
.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}
.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}
.selectric-items li {
  display: block;
  padding: 8px;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #EEE;
  color: #666;
  cursor: pointer;
}
.selectric-items li.selected {
  background: #EFEFEF;
  color: #444;
}
.selectric-items li:hover {
  background: #F0F0F0;
  color: #444;
}
.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: none;
  color: #444;
}
.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}
.selectric-usr_prof.selectric-wrapper{
	padding: 0 !important; margin: 0 !important;
	background-image: none !important;
	height: 100%;
}

.selectric-usr_prof.selectric-wrapper.selectricOpen{
	z-index: 9999999;
}
.selectric-usr_prof .selectric{
	border-radius: 0 !important;
	background-color: #fff !important;
	border: none !important;
	height: 100%;
}
.selectric-usr_prof .selectric p, .selectric-usr_prof .selectric img{
	display: inline-block;
	vertical-align: middle;
}
.selectric-usr_prof .selectric p{
	width: 188px;
	padding: 0;
	padding-left: 5px; margin-right: 5px !important;
	color: #727272 !important;
	font-size: 18px !important;
	text-indent: 0.01px !important;
	line-height: 32px !important;
}
.selectric-usr_prof .selectric img{
	margin-top: 1px;
	width: 15px;
}
.selectric-usr_prof.selectric-wrapper .selectric-items{
	background: #fbfbfb;
	border: none;
	z-index: 99999999;
}
.selectric-usr_prof.selectric-wrapper ul{
	margin: 0 !important;
}
.selectric-usr_prof.selectric-wrapper li{
	list-style: none;
	border-bottom: none !important;
	color: #727272;
	font-size: 15px;
	position: relative;
	text-align: left;
}
.selectric-usr_prof.selectric-wrapper li .remove, .selectric-usr_prof.selectric-wrapper .selectric-hide-select{
	display: none;
}
.selectric-usr_prof.selectric-wrapper li .option-buttons{
	display: block;
	height: 18px;
	width: auto;
	max-height: 18px;
	overflow: hidden;
	position: absolute;
	right: 14px;
	top: 8px;
	z-index: 999999999;
}
.selectric-usr_prof.selectric-wrapper li .option-buttons a{
	text-decoration: none !important;
}
.selectric-usr_prof.selectric-wrapper li .option-buttons i{
	font-size: 18px;
	color: #3A86B1;
	cursor: pointer;
	z-index: 999999999
}
.selectric-usr_prof.selectric-wrapper li .option-buttons i:hover{
	color: #e5b143;
}

form.file-picker-form{
	text-align: center;
}
form.file-picker-form label{
	vertical-align: middle;
	display: inline-block;
	font-size: 18px;
	text-transform: uppercase;
}
.customfile-wrap {
	display: inline-block;
	position: relative;
	width: 225px;
	top: -11px;
	margin-left: 4px;
}
.customfile-filename, .customfile-upload {
	margin: 0;
	padding: 0;
}
.customfile-filename {
	height: 30px !important;
	width: 146px;
	font-size: 18px;
	background-color: #fff;
	text-align: left;
	color: #000;
	padding-left: 10px;
	border: none !important;
	border-width: 0px;
	box-shadow: inset 0 1px 2px rgba(0,0,0,.2);
	position: absolute;
	top: 0;
	left: 0;
}
.customfile-filename:focus {
	outline: none;
}
.customfile-upload {
	line-height: 32px;
	height: 28px;
	text-transform: uppercase;
	display: inline-block;
	padding: 0 8px;
	border: 1px solid #000;
	color: #000;
	background: #ddd;
	margin-left: -1px; /* align with input */
	cursor: pointer;
	background: #3a86b1;
	background: -moz-linear-gradient(top, #e5b143 0%, #e5b143 100%);
	background: -webkit-linear-gradient(top, #e5b143 0%, #e5b143 100%);
	background: -o-linear-gradient(top, #e5b143 0%, #e5b143 100%);
	background: -ms-linear-gradient(top, #e5b143 0%, #e5b143 100%);
	background: linear-gradient(to bottom, #e5b143 0%, #e5b143 100%);
	font-size: 12px;
	background: #e5b143 url(../images/browse.png) center center no-repeat;
	width: 52px;
	position: absolute !important;
	top: 0;
	color:#000;
	right: 0;
}
.customfile-upload:hover {
	background: #000 url(../images/browse.png) center center no-repeat;
	box-shadow: 0 0 2px rgba(0,0,0,.2);
}
.customfile-upload::-moz-focus-inner { /* Fix firefox padding */
 padding: 0;
border: 0
}

.ui-autocomplete{
	border-radius: 0 !important;
	z-index: 999999999;
}

.table_row.survey .ind_division_6{
	width: 69px !important;
}
.table_row.survey .ind_division_7{
	width: 499px !important;
}

.table_row.survey .ind_division_6 select{
	width: 100%;
	height: 29px;
	border-radius: 0;
	font-size: 14px;
	padding: 5px 20px 5px 16px;
	border: none;
	border-bottom: 1px solid #000;
	background-image: url('../images/Down-Arrow-Gray.png');
	background-position: 85% 60%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-color: #fff;
	color: #000;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.table_row.survey .ind_division_6 select::-ms-expand {
    display: none;
}

.table_row .ind_division_7 input{
	width: 100%;
	display: block;
	text-indent: 5px;
	color: #000;
	border-bottom: 1px solid #000;
	height: 28px;
	cursor: pointer;
}

div.findings{
	background-color: #efefef;
	height: auto;
	padding: 15px 15px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 16px !important;
	width: 100%;
	color: #000 !important;
	text-align: left;
}
div.findings *{
	color: #000 !important;
}

.pop_up textarea.comment_big{
	max-width: 100%;
}

.pop_up.survey, .pop_up.facesheet, .pop_up.bodynoscroll{
	max-height: 95%;
	height: 95%;
	overflow: hidden;
	padding: 12px 0 60px 0 !important;
	position: fixed !important;
	left: 50% !important;
	margin-left: -600px !important;
	width: 1200px !important;
}
.pop_up.analyticsPrint{
	z-index: 9001 !important
}
.pop_up.survey .scroll, .pop_up.facesheet .scroll, .pop_up.bodynoscroll .scroll{
	-ms-overflow-style: -ms-autohiding-scrollbar;
	overflow: scroll;
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
	padding: 0px 20px;
}

.pop_up.survey #popup_buttons, .pop_up.bodynoscroll #popup_buttons{
	position: absolute;
	bottom: 0;
	left: 0;
	background: #508aac;
	padding: 10px 20px;
	margin: 0;
	height: 60px;
}
.pop_up.facesheet #popup_buttons {
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: #508aac !important;

	padding: 10px 20px;
	margin: 0;
	height: 60px;
}
.pop_up.facesheet, .pop_up.bodynoscroll{
	text-align: left;
}
.pop_up.facesheet h1{
	display: inline-block;
	width: 33%;
	margin-bottom: 0px !important;
}
.pop_up.facesheet .area{
	margin: 8px 0;
	clear: both;
}
.pop_up.facesheet .area .area-title{
	font-size: 20px;
}
.pop_up.facesheet h1, .pop_up.default .scroll > h1{
	color: #ffffff;
	font-size: 24px;
}
.pop_up.bodynoscroll h1{
	color: #e5b143;
	font-size: 24px;
}

.pop_up.bodynoscroll *, .pop_up.facesheet *{
	line-height: 100%;
}
.pop_up.bodynoscroll .area, .pop_up.facesheet .area{
	background: #fff;
	margin-top: 12px;
}
.pop_up.bodynoscroll .area .area-title, .pop_up.facesheet .area-title{
	background: #fee0ae;
	display: block;
	/*border: 1px solid #FCD984;*/
	color: #000;
	font-size: 20px;
	padding: 4px 10px 6.5px 10px;
	margin: 0 !important;
}
.pop_up.bodynoscroll .area .area-title > span, .pop_up.facesheet .area-title > span{
	color: #000 !important;
	display: inline-block;
	margin-top: -2px;
	font-size: 12px;
}

.pop_up.bodynoscroll a.right, .pop_up.facesheet a.right{
	float: right;
}
.pop_up.bodynoscroll .area .area-title > a.right, .pop_up.facesheet .area .area-title > a.right{
	margin-top: 3px;
	font-size: 16px;
}
.pop_up.bodynoscroll a:not(.ptbutton), .pop_up.facesheet a:not(.ptbutton){
	color: #3a86b1 !important;
	text-decoration: none;
	font-size: 14px;
}
.pop_up.bodynoscroll .area .inputs, .pop_up.facesheet .area .inputs{
	padding: 12px 12px;
	position: relative;
}
.pop_up.bodynoscroll .area .inputs .absoluteRight, .pop_up.facesheet .area .inputs .absoluteRight{
	position: absolute;
	right: 12px;
	top: 12px;
}
.pop_up.bodynoscroll .area:after, .pop_up.bodynoscroll .area:before, .pop_up.facesheet .area:after, .pop_up.facesheet .area:before{
	content: ' ';
	display: block;
	clear: both;
}
.pop_up.bodynoscroll ul.checkboxes, .pop_up.facesheet ul.checkboxes{
	list-style: none !important;
	width: 100%;
	margin: 0 !important;
}
.pop_up.bodynoscroll ul.checkboxes li, .pop_up.facesheet ul.checkboxes li{
	float: left;
	vertical-align: top;
	width: 25%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0px 10px 10px 10px;
}
.pop_up.default label
{
	color: #fff;
	text-align: left;
	font-size: 125%;
	font-family: 'Lato', sans-serif;
	display: block;
	margin: 18px 0 10px 0;
}
.pop_up.default textarea
{
	font-size: 14px !important;
	font-family: sans-serif, lato !important;
	border: 1px solid #ccc;
	outline: none;
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px;
    color: #727272 !important;
}


.page_wrap{
	padding: 0px 14px;
}
.wrap_half{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding-right: 10px;
	width: 50%;
	float: left;
}
.wrap_half + .wrap_half{
	padding-left: 10px;
	padding-right: 0;
}

.form_section *{
	line-height: 100%;
}

.form_section p.section_title{
	background: #fefbe2;
	display: block;
	border: 1px solid #e5b143;
	color: #000;
	font-size: 18px;
	padding: 4px 10px;
	margin: 0 0 10px 0 !important;
}

.form_section .input-wrap{
	padding: 0 12px;
	margin-bottom: 8px;
}

.form_section .input-wrap:before, .form_section .input-wrap:after{
	content: ' ';
	display: block;
	visibility: hidden;
	font-size: 0;
	clear: both;
}
.form_section .input-wrap p{
	margin: 5px 0 !important;
}
.form_section .input-wrap label{
	width: 35%;
	font-size: 16px;
	margin-top: 9px;
	text-align: left;
}
.form_section .input-wrap input, .form_section .input-wrap select{
	width: 65%;
	height: 32px;
	border: 1px solid #ccc;
	text-indent: 5px;
	font-size: 18px;
	outline: none;
	color: #727272 !important;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.form_section .input-wrap select{
	border: 1px solid #ccc !important;
}
.form_section .input-wrap select{
	padding-left: 0 !important;
}

.form_section .input-wrap input::-webkit-input-placeholder {
   line-height: 110%;
}

.form_section .input-wrap input:-moz-placeholder { /* Firefox 18- */
   line-height: 110%;
}

.form_section .input-wrap input::-moz-placeholder {  /* Firefox 19+ */
   line-height: 110%;
}

.form_section .input-wrap input:-ms-input-placeholder {
	line-height: 110%;
}
.form_section .input-wrap.small-input label{
	width: 50%;
}
.form_section .input-wrap.small-input input{
	width: 50%;
}
.form_section .input-wrap.only-input input{
	width: 100%;
}

.form_section .input-wrap label, .form_section .input-wrap input, .repeater .input-wrap a.repeater-delete{
	float: left;
	display: block;
	vertical-align: middle;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.repeater .input-wrap input{
	width: 95%;
}

.repeater a.repeater-add{
	font-size: 28px;
	text-decoration: none;
	display: block;
	text-align: right;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin-bottom: 5px;
}

.repeater .input-wrap a.repeater-delete{
	width: 5%;
	font-size: 18px;
	height: 32px;
	width: 18px;
	text-decoration: none;
	padding-top: 10px;
	padding-left: 1.5px;
	margin-left: 1.8%;
	margin-left: calc(5% - 18px);
	overflow: hidden;
	color: gray;
}
.repeater .input-wrap a.repeater-delete:hover{
	color: #3a86b1;
}

ul.bullet-img{
	list-style: none !important;
	margin-left: 12px !important;
	margin-bottom: 10px;
}
ul.bullet-img li{
	background-image: url('../images/list_bullet.png');
	background-repeat: no-repeat;
	background-position: 0% 49%;
	padding-left: 14px;
	line-height: 130%;
}

.no-margin-bottom{
	margin-bottom: 0 !important;
}
.pop_up.facesheet{
	z-index: 9999999 !important;
	top: 2.5%;
}
.pop_up.facesheet .form-third img{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: 1px solid #ccc;
	width: 300px;
	display: block;
	margin-left: 10px;
}
#facesheet-img-wrap{
	position: relative;
}
#facesheet-img-wrap #uploadImageButtonWrap{
	position: absolute;
	bottom: 0;
	left: 10px;
	width: 300px;
	z-index: 9999;
	height: 30px;
	background: #3a86b1;
	background: rgba(58,134,177, .8);
	text-align: center;
}
#facesheet-img-wrap #uploadImageButtonWrap > span{
	line-height: 30px;
	color: #fff;
	font-size: 14px;
}
#facesheet-img-wrap .uploadImage{
	position: absolute !important;
	bottom: 0; right: 0;
	z-index: 9999;
	background-image: url('/assets/fine-uploader/upload.png');
	background-repeat: no-repeat;
	background-size: 60%;
	background-position: 50%;
	display: block;
	min-width: 30px;
	min-height: 30px;
	background-color: #e5b143;
}
#facesheet-img-wrap .qq-upload-button-hover{
	background-color: #3a86b1;
}

.pop_up.facesheet #imageDrop{
	position: absolute;
	top: 0;left:10px;
	width: 300px;
	height: 100%;
	overflow: hidden;
	opacity: 0;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.pop_up.facesheet #imageDrop.show{
	opacity: 1;
}

.pop_up.facesheet #imageDrop .qq-uploader{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	background: #fff;
	max-height: none !important;
	overflow: hidden;
	padding: 0;
	position: relative;
}

.pop_up.facesheet #imageDrop .qq-upload-drop-area{
	background: #fee0ae;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.pop_up.facesheet #imageDrop .qq-upload-drop-area:before{
	content: attr(qq-drop-area-text) " ";
	position: absolute;
	font-size: 200%;
	line-height: 32px;
	left: 0;
	width: 100%;
	text-align: center;
	top: 50%;
	margin-top: -16px;
	color: #fff;
}

.pop_up.facesheet #imageDrop .qq-total-progress-bar-container{
	display: block;
	position: absolute;
	width: 100%;
	top: 50%;
	margin-top: -10px;
}

.pop_up.facesheet #imageDrop .qq-total-progress-bar-container .qq-progress-bar{
	margin: 0 !important;
}
.pop_up.facesheet .input-wrap{
	position: relative;
}
.pop_up.facesheet label{
	font-size: 14px !important;
}
.pop_up.facesheet label.error{
	font-size: 12px !important;
	color: #ff6666 !important;
	margin: 0;
	position: absolute;
	bottom: -5px;
	width: 100%;
}
.pop_up.facesheet .only-input label.error{
	bottom: -14px;
}
.pop_up.facesheet input, select{
	font-size: 16px !important;
	font-family: sans-serif, lato !important;
}

.pop_up.facesheet input + span, .pop_up.facesheet .only-input label.error + span{
	display: block;
	margin-top: 5px;
	float: left;
}

.pop_up.facesheet .input-wrap.only-input{
	margin-bottom: 29px;
}

.pop_up.facesheet .hasDatepicker{
	font-weight: normal !important;
}

.pop_up.facesheet table{
	border: none !important;
}
.pop_up.facesheet table th{
	border-top: none !important;
	border-left: 1px solid #fff;
	border-right: 1px solid #fff;
}
.pop_up.facesheet table thead tr > th:first-child, .pop_up.facesheet table tbody tr > td:first-child, .pop_up.facesheet table tbody tr > th:first-child{
	border-left: none !important;
}
.pop_up.facesheet table thead tr > th:last-child, .pop_up.facesheet table tbody tr > td:last-child, .pop_up.facesheet table tbody tr > th:last-child{
	border-right: none !important;
}
.pop_up.facesheet table input{
	border: none !important;
}
.pop_up.facesheet .surveys td{
	line-height: 28px;
}
.pop_up.facesheet td{
	border-color: #ccc;
}

.pop_up.facesheet .indicator_desc_close, .pop_up.facesheet .indicator_desc_close i{
	line-height: 130%;
}
.very-poor{
	background-color: #ff797f !important;
}
.poor{
	background-color: #ffa09d !important;
}
.fair{
	background-color: #e5b143 !important;
}
.good{
	background-color: #C6E1A9 !important;
}
.very-good{
	background-color: #7beb9e !important;
}

.pop_up.facesheet table.surveys, .pop_up.facesheet table.indicators{
	width: 100%;
	border-collapse: collapse;
}
.pop_up.facesheet table.surveys a, .pop_up.facesheet table.indicators a{
	text-decoration: underline;
}
.pop_up.facesheet table.surveys th, .pop_up.facesheet table.surveys tfoot td, .pop_up.facesheet table.indicators th, .pop_up.facesheet table.indicators tfoot td{
	color: #000;
	border-right: 1px solid #000;
	border-bottom: 1px solid #000;
	padding: 2px 0;
	background-color: #e5b143;
	text-transform: uppercase;
	border-collapse: collapse;
	position: relative;
	height: 29px;
	vertical-align: middle;
	text-align: center;
	line-height: 120% !important;
}
.pop_up.facesheet table.surveys thead tr > th:last-child, .pop_up.facesheet table.indicators thead tr > th:last-child{
	border-right: none !important;
}
.pop_up.facesheet table.surveys tbody, .pop_up.facesheet table.indicators tbody{
	font-size: 12px;
}
.pop_up.facesheet table.surveys tbody tr, .pop_up.facesheet table.indicators tbody tr{
	border-bottom: 1px solid #000;
}
.pop_up.facesheet table.surveys tbody tr > td.rows > div, .pop_up.facesheet table.indicators tbody tr > td.rows > div{
	height: 30px;
	padding: 2px 0;
	text-align: center;
	line-height: 29px !important;
	border-bottom: 1px solid #ccc;
	position: relative;
}
.pop_up.facesheet table.surveys tbody tr > td.rows > div > span, .pop_up.facesheet table.indicators tbody tr > td.rows > div > span{
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	position: absolute;
	width: 100%;
	left: 0;
	top: 11px;
}
.pop_up.facesheet table td.rows .scroll-x{
	text-indent: 5px;
	overflow: auto !important;
	text-overflow: inherit !important;
}
.pop_up.facesheet table.surveys tbody tr > td.rows > div:last-child, .pop_up.facesheet table.indicators tbody tr> td.rows > div:last-child{
	border-bottom: none !important;
}
.pop_up.facesheet table.surveys tbody td, .pop_up.facesheet table.indicators tbody td{
	padding: 0;
	border-right: 1px solid #000;
	border-bottom: none !important;
	vertical-align: top;
}
.pop_up.facesheet table.surveys tbody td.answer, .pop_up.facesheet table.indicators tbody td.answer{
	text-align: center;
	color: #000;
	vertical-align: middle !important;
}
.pop_up.facesheet table tfoot{
	display: table-footer-group;
}
.pop_up.facesheet table tfoot td{
	border-bottom: none;
	border-right: none;
}
.pop_up.facesheet table tfoot td.border-right{
	border-right: 1px solid #000;
}

.pop_up.facesheet table .scrollY{
	overflow: scroll;
	overflow-x: hidden;
	overflow-y: scroll;
	padding: 8px 5px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	line-height: 130% !important;
}
.pop_up.facesheet table .scrollY *{
	line-height: 130% !important;
}
.pop_up.facesheet table .scrollY p:first-child{
	margin-top: 0 !important;
}
.pop_up.facesheet table.surveys .scrollY{
	max-height: 140px;
}
.pop_up.facesheet table.indicators .scrollY{
	max-height: 210px;
}
.pop_up.facesheet table.indicators .scrollY.smaller{
	height: auto !important;
	padding: 8px 5px !important;
	text-align: left !important;
	line-height: 100% !important;
	max-height: 175px;
}

.pop_up.facesheet table p, .pop_up.facesheet table span{
	color: #000 !important;
	font-size: 14px !important;
}

.form-row{
	padding: 20px 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.form-row:before, .form-row:after{
	content: ' ';
	display: block;
	visibility: hidden;
	font-size: 0;
	clear: both;
}


.form-third{
	width: 35.5%;
	float: left;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0 10px;
}

.form-first-less{
	width: 28% !important;
}

.form-third p.title{
	padding: 0 12px;
	margin: 0 0 20px 0;
	color: #000;
	font-size: 16px;
	font-weight: bold;
}

.form-row > .form-third:first-child{
	padding-left: 0 !important;
}
.form-row > .form-third:last-child{
	padding-right: 0 !important;
}
.filterSelectors li.title {
	padding: 0;
	height: auto;
}
.filterSelectors li {
	box-sizing:border-box;
	width:50%;
	float:left;
	text-align: center;
	color: #e5b143;
	list-style: none;
	margin:0 0 25px 0;
	opacity: 0.5;
	min-height: 90px;
	height: 100px;
	position: relative;
	padding-top:75px;
    text-align: center;
    vertical-align: bottom;
    overflow: hidden;
}

.filterSelectors li span {
	position: absolute;
	top: 20px;
	left: 0;
	height: 45px;
	width: 100%;
}

.filterSelectors li.inactive span {
	color: lightgrey;
}

.filterSelectors li.full-width {
	width:100%;
	text-align: left;
	font-size:18px;
	min-height: auto;
}
.filterSelectors li.active, .filterSelectors li.title {
	opacity: 1;
}
.filterSelectors li span {
	display:block;
	font-size:45px;
	height:45px;
}

/* testimonials */

ul.testimonial{
	margin-left: 0 !important;
	list-style: none !important;
}
li.testimonial{
	float: left;
	width: 33.3%;
	padding: 14px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin-bottom: 30px;
	vertical-align: top;
}
.tleft{
	float: left;
	width: 30%;
	text-align: center;
}
.tright{
	float: right;
	width: 67%;
	margin-left: 3%;
	border: 2px solid #e5b143;
	padding: 5px 0;
	color: #727272;
	background: #fefbe2;
	text-align: left;
}
.tright-inner{
	min-height: 95px;
	height: 185px;
	max-height: 185px;
	width: 100%;
	padding: 0 10px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	overflow: auto;
	background: #fefbe2;
}
.tleft, .tright{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.tleft span{
	color: #3a86b1;
}
.icon_wrap{
	max-height: 90px;
	overflow: hidden;
	text-align: center;
}
.testimonial .icon{
	color: #e5b143;
	font-size: 80px;
}

/*paginate*/
.paginate_toggles{
	margin: 0px auto;
	overflow: hidden;
	max-height: 33px;
	text-align: center;
	margin-bottom: 10px;
}
.paginate_toggles .toggle{
	color: #b5b5b5;
	display: inline-block;
	margin: 0 10px;
	font-size: 33px;
}
.paginate_toggles .pointer{
	color: #e5b143;
}

.banner-small{
	border: 1px solid #e5b143;
	padding: 2px 10px;
	background: #fefbe2;
	margin: 0px auto;
	width: 50%;
	text-align: center;
}
.banner-small p{
	font-size: 16px;
	margin: 5px 0 5px 0 !important;
	line-height: 140%;
}
.banner-small h2{
	color: #000;
	font-size: 20px;
	letter-spacing: .5px;
}
.hasResidents {
	overflow: hidden;
	width:158px;
	float: left;
}
.scroll h1 {
	margin-bottom:10px;
}
.pop_up.load, .pop_up.tan{
	border: 2px solid #e5b143;
	background: #fefbe2;
	top: 38%;
}
@media only screen and (max-width: 500px){
	.pop_up.tan.two_auth{
		top: 60vh;
	}
}
.pop_up.load p, .pop_up.tan p {
	color: #000;
}
.errors {
	color:red;
	text-transform: uppercase;
}


.indicatorWrap{
	position: relative;
}
.replicateCheckboxDiv{
	cursor: pointer;
	background: #fff;
	background: rgba(0,0,0,.4);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	width: calc(100% + 1px);
	height: 100%;
	z-index: 999;
}
.replicateCheckboxDiv .fa{
	display: block;
	position: absolute;
	font-size: 64px;
	left: 50%; /*fallback*/
	top: 50%; /*fallback*/
	left: calc(50% - 32px);
	top: calc(50% - 32px);
	color: #fff;
	cursor: pointer;
}
.replicateCheckboxDiv.selected .fa-check{
	display: block !important;
	color: #62F119;
	top: 79px;
	top: calc(50% - 34px);
	left: 567.500px;
	left: calc(50% - 30px);
}
.replicateCheckboxDiv.selected{
	background: #7BEB9E;
	background: rgba(123,235,158,.2);
	border: 3px solid #7BEB9E;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.indicatorStickyWrapper{
	margin-top: 8px;
	margin-bottom: 8px;
}
#stickyMenu{
	position: absolute;
	width: 100%;
	height: 30px;
	z-index: 9999;
	margin-top: 8px;
	margin-bottom: 8px;
}
#stickyMenu + .table_row{
	margin-top: 46px;
}
#stickyMenu button{
	border: 1px solid #34789f;
	padding: 6px 8px;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
}
#stickyMenu button.disabled{
	background-color: #557e96;
	cursor: not-allowed;
}

#superLoginWrap{
	position: absolute;
	right: 0;
	top: 18px;
}
.superLogin{
	color: black;
	font-size: 16px;
	font-weight: bold;
	margin-right: 5px;
}
.safari_fix {
  position: fixed;
  width: 1px;
  left: -50px;
}
.safari_fix input {
    width: 1%;
}

/*Education page styles*/
.nnhqcc:before, .qsst:before, .qlrt:before, .pip_inventory:before, .flowchart:before, .pdsa_template:before, .pip_checklist:before, .fishbone:before, .pip_charter:before, .midw:before, .devdbd:before, .qapiplan:before, .complan:before{
	display: 'block';
	content: ' ';
	clear: both;
}
.nnhqcc, .qsst, .qlrt, .fishbone, .pip_charter, .pip_inventory, .midw,  .flowchart, .pip_checklist, .pdsa_template, .devdbd, .qapiplan, .complan{
	margin: 50px 0 14px 0;
	position: relative;
}
.nnhqcc .brown{
	color: #af5a28 !important;
}
.nnhqcc > img{
	display: block;
	margin-bottom: 20px;
}
.nnhqcc h3{
	color: #007aa9;
}
.nnhqcc h3 + p{
	margin-top: 8px !important;
	margin-bottom: 8px !important;
}
.nnhqcc p + h3{
	margin-top: 20px;
}
.nnhqcc ul.center, .nnhqcc ol.center{
	margin: 0 auto 20px auto !important;
	display: table;
}
.nnhqcc ul.open{
	list-style: circle !important;
}
.nnhqcc .title, .nnhqcc .subtitle, .qsst .title, .qapiplan .title, .fishbone .title, .pdsa_template .title, .qlrt .title, .pip_inventory .title, .pip_checklist .title, .flowchart .title, .qapipurpose .title, .performance_objectives .title, .pip_charter .title, .complan .title, .devdbd .title{
	width: 100%;
	padding: 5px 8px;
	box-sizing: border-box;
	margin-bottom: 20px;
}
.nnhqcc .title{
	height: 40px;
	background: #007aa9;
}
.nnhqcc .subtitle, .qsst .title, .qlrt .title .pip_charter .title{
	height: 35px;
	background: #732c9c;
}
.nnhqcc .title > h1, .pip_charter .title > h1, .devdbd .title > h1, .qapiplan .title > h1{
	color: #fff;
	font-size: 24px;
	line-height: 30px;
}
.nnhqcc .subtitle > h1, .qsst .title > h1, .qlrt .title > h1, .pip_inventory .title > h1, .pdsa_template .title > h1, .flowchart .title > h1, .pip_checklist .title > h1, .fishbone .title > h1, .qapipurpose .title > h1, .performance_objectives .title > h1, .complan .title > h1, .pip_charter .title > h1, .devdbd .title > h1, .micmp .title > h1, .midw .title > h1{
	color: #fff;
	font-size: 20px;
	line-height: 25px;
}
.nnhqcc ol{
    counter-reset: li 1;
    counter-increment: subsection 0;
    margin-left: 0 !important;
}
.nnhqcc ol > li{
	list-style: none;
	counter-increment: li 0;
	counter-increment: number 0;
	margin-bottom: 5px;
}
ol.x1 > li:before{
  content: counter(li) "." counter(subsection, lower-alpha) "\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
  counter-increment: subsection;
}
ol > li:first-child{
	counter-reset: subsection;
}
.nnhqcc div.margin-left{
	margin-left: 40px;
}
ol.x2 > li:first-child{
	counter-increment: subsection 1 number 0;
}
ol.x2 > li:before{
  content: counter(li) "." counter(subsection, lower-alpha) "." counter(number) "\00a0\00a0";
  counter-increment: number;
}
.nnhqcc ol > li > ul, .nnhqcc ul > li > ul{
	display: table;
	margin: 8px 0 8px 40px !important;
}

.qsst .title, .qlrt .title{
	background: #0078ab;
	width: 1025px !important;
	float: left;
}
.qsst .blue, .qlrt .blue, .fishbone .blue, .qapipurpose .blue, .pip_inventory .blue, .pdsa_template .blue, .qapiplan .blue, .flowchart .blue, .pip_checklist .blue, .pip_charter .blue, .performance_objectives .blue, .devdbd .blue, .micmp .blue, .midw .blue, .complan .blue{
	color: #0078ab !important;
}
.qsst table, .qlrt table, .fishbone table, .qapipurpose table, .pip_inventory table, .pdsa_template table, .pip_charter table, .performance_objectives table, .micmp table, .midw table, .complan table, .qapipurpose table, .pop_charter table, .devdbd table{
	border-collapse: collapse;
	table-layout: fixed;
	width: 100%;
	font-weight: normal;
}
.qsst table th{
	text-transform: none;
	text-align: center;
	padding: 5px;
	line-height: 130% !important;
	font-weight: lighter;
	background: #feedd3;
}
.qsst table th:first-child{
	font-size: 16px;
	font-weight: bold !important;
}
.qsst table td, .qlrt table td{
	border: 1px solid #000;
	padding: 5px 15px 30px 5px;
}
.qsst .footerdisclaimer{
	margin: 25px 0 0 0 !important;
	padding-left: 6px !important;
	padding-top: 5px;
	position: relative;
}
.qsst .footerdisclaimer:before{
	content: " ";
	display: block;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	heigqht: 1px;
	background: #0078ab;
}

.qlrt .qlrt_subtitle{
	border: 1px solid black;
	box-sizing: border-box;
	padding: 2px 5px;
}

.qlrt .qlrt_olist{
	display: table;
	margin-left: 24px;
	text-align: left;
	margin-bottom: 14px !important;
}

.qlrt .qlrt_olist li{
	padding-left: 10px;
	margin-bottom: 2px;
}

.qlrt .qlrt_olist .no-margin-bottom{
	margin-bottom: 0 !important;
}
.qlrt .qlrt_box > p:first-child{
	margin-top: 0 !important;
}
.qlrt .qlrt_box{
	border: 1px solid black;
	box-sizing: border-box;
	padding: 2px 5px;
	text-align: left;
}
.qlrt .qlrt_box.empty{
	height: 200px;
	width: 100%;
}

.qlrt table{
	text-align: left;
}
.qlrt table td{
	vertical-align: top;
	padding: 2px 5px;
}

.fishbone .title{
	background: #0078ab;
	width: 1025px !important;
	float: left;
}

.fishbone .fishbone_ul{
	list-style-image: none !important;
	list-style: none;
	display: table;
	margin-left: 30px !important;
}

.fishbone .fishbone_ul li{
	margin-bottom: 2px;
}
.fishbone .fishbone_ul li:before, .qapipurpose ul li:before{
	content: "• ";
	font-size: 30px;
	vertical-align: middle;
}
.center-image{
	display: block;
	margin: 0px auto;
}

.fishbone ol li{
	padding-left: 10x;
	margin-bottom: 10px;
}

.fishbone ul.center, .fishbone .ol.center{
	display: block;
	margin: 0px auto !important;
}

.fishbone ul.square li:before{
	content: "◼ \00a0" !important;
	font-size: 10px;
}
.fishbone table.center th{
	padding: 2px 0 12px 0 !important;
	font-weight: normal;
}
.fishbone table.center th, .fishbone table.center td{
	border: 1px solid #000;
	background: none;
	line-height: 130% !important;
}
.fishbone table:not(.center) th, .fishbone table:not(.center) td{
	border: 1px solid #000;
	background: none;
	padding: 2px 0 12px 5px !important;
	line-height: 130% !important;
	vertical-align: top;
}
.fishbone table:not(.center) th{
	padding: 0px 0 12px 5px !important;
	font-weight: normal;
}
.fishbone table:not(.center) td{
	line-height: 100% !important;
}
.fishbone table:not(.center) tr > td:first-child img{
	margin-left: 10px;
	height: 15px;
}
.fishbone table.center{
	margin: 0px auto;
}
.fishbone table{
	width: 30%;
	margin-top: 12px !important;
	text-align: center;
}

.fishbone table.rca{
	text-align: left;
	width: 100%;
}
.fishbone table.rca th{
	height: auto;
}
.fishbone table.rca th, .fishbone table.rca td{
	padding: 2px 5px 2px 5px !important;
}
.fishbone table.rca.no-border{
	margin: 0 !important;
}
.fishbone table.rca.no-border th, .fishbone table.rca.no-border td{
	margin: 0 !important;
	border: none !important;
}
.fishbone table.rca td{
	line-height: 130% !important;
}

.fishbone .subtitle{
	color: black;
	background: #dadada;
	box-sizing: border-box;
	padding: 4px;
	width: 100%;
	text-indent: 5px;
	font-weight: bold;
	border: 1px solid black;
}

.fishbone ul.ml{
	margin-left: 20px !important;
}
.fishbone ul.circle{
	list-style-image: none !important;
	list-style: none;
}
.fishbone ul.circle li:before{
	content: "○ \00a0";
}

.fishbone table.emptyTable th{
	padding: 3px 5px !important;
	height: auto !important;
}
.fishbone table.emptyTable.grayBG th{
	padding: 3px 0 12px 0 !important;
	height: auto !important;
	background: #dadada;
}
.fishbone table.emptyTable td{
	height: 40px;
}

/*---------------------------------------------------*/

.qapiplan .title{
	background: #0078ab;
	width: 1025px !important;
	float: left;
}
/*---------------------------------------------------*/

.qapipurpose .title{
	background: #0078ab;
	width: 1025px !important;
	float: left;
}

.qapipurpose h2{
	color: #0078ab !important;
	font-size: 18px;
	text-align: center;
}

.qapipurpose ol.outer{
	counter-reset: li 1;
}

.qapipurpose ol.outer > li:before{
	content: counter(subsection, lower-alpha) "\00a0\00a0\00a0\00a0\00a0";
  	counter-increment: li;
}

.qapipurpose ol.outer > li{
	font-weight: bold;
}

.qapipurpose ul{
	list-style-image: none !important;
	list-style: none !important;
	margin: 0 !important;
}

.qapipurpose ul > li{
	margin-bottom: 8px;
}

.qapipurpose table td{
	border: 1px solid #000;
	padding: 2px 4px 24px 2px;
}

/*---------------------------------------------------*/

.pip_charter .title{
	background: #0078ab;
	width: 1025px !important;
	float: left;
}

.pip_charter > h3{
	display: block;
	background: #95b2de;
	box-sizing: border-box;
	padding: 4px 8px;
	border: 1px solid #000;
	font-size: 14px;
	margin-bottom: 4px;
}

.pip_charter table{
	text-align: left;
}
.pip_charter table th, .pip_charter table td{
	vertical-align: top;
}
.pip_charter table th{
	background: #95b2de !important;
	padding: 2px 0 2px 8px;
	font-weight: bold;
	border: 1px solid #000;
}

.pip_charter table td{
	border: 1px solid #000;
	padding: 4px 0 4px 8px;
}

.pip_charter table.padding td{
	padding-bottom: 40px !important;
}

/*---------------------------------------------------*/


.devdbd .title, .micmp .title, .midw .title{
	background: #0078ab;
	width: 1025px !important;
	float: left;
}

.devdbd .title > h1, .micmp .title > h1, .midw .title > h1{
	line-height: 45px !important;
}

.devdbd ul.square{
	list-style-image: none !important;
	list-style-type: none !important;
	margin: 0 0 0 30px !important;
}

.devdbd ul.square li:before{
	content: "□ \00a0";
	font-size: 30px;
	vertical-align: middle;
	display: inline-block;
	margin-top: -4px;
}

.devdbd ul.square li{
	margin-bottom: 12px;
}

/*---------------------------------------------------*/

.micmp table{
	text-align: left;
}

.micmp table th, .micmp table td{
	border: 1px solid #000;
	padding: 4px 0 4px 8px;
}

.micmp table th{
	background: #0078ab;
	color: #fff;
	white-space: none;
}

.micmp table tr > td:empty{
	height: 40px;
}

/*---------------------------------------------------*/

.midw .midw_measure{
	text-indent: -1em;
  	padding-left: 1em;
}

.midw h3{
	font-size: 16px;
	font-weight: bold;
	border: 1px solid #000;
	display: block;
	width: 100%;
	box-sizing: border-box;
	padding: 4px 5px 4px 5px;
}

.midw table th, .midw table td{
	vertical-align: top;
}

.midw table td + td{  
	font-style:italic;
}

/*---------------------------------------------------*/

.complan .title{
	background: #0078ab;
	width: 1025px !important;
	float: left;
}

.complan table thead th {
	font-weight:bold;
}


.complan table tbody tr td p {
	font-weight:bold;
	font-style:italic;
}

/*---------------------------------------------------*/

.performance_objectives .title{
	background: #0078ab;
	width: 1025px !important;
	float: left;
}

/*---------------------------------------------------*/

#pdfLinks{
	margin-top: 12px;
	width: 100%;
	box-sizing: border-box;
}
#pdfLinks:after{
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
#pdfLinks > ul{
	list-style: none !important;
	list-style-image: none !important;
	margin: 0 !important;
}
#pdfLinks > ul > li{
	float: left;
	white-space: nowrap;
	margin: 0 !important;
	margin-bottom: 8px !important;
	vertical-align: top;
	width: 20%;
}
#pdfLinks > ul > li:first-child, #pdfLinks > ul > li:nth-child(6n + 0){
	margin-left: 0 !important;
}
#pdfLinks > ul > li > a{
	display: block;
	max-width: 202px;
}
#pdfLinks img{
	max-width: 200px;
	display: block;
	border: 2px solid #000;
	margin: 0px auto;
}

#pdfLinks > ul > li:nth-child(6n + 0){
	clear: both;
}


/*---------------------------------------------------*/

.pip_inventory .title{
	background: #0078ab;
	width: 1025px !important;
	float: left;
}

.pip_inventory table{
	text-align: left;
}

.pip_inventory table th, .pip_inventory table td{
	border: 1px solid #000;
	padding: 4px 0 4px 8px;
}

.pip_inventory table th{
	background: #79a6d2;
	color: #000;
	white-space: none;
	vertical-align: top;
}

.pip_inventory p {
	padding:4px 0 0 4px;
}

.pip_inventory table tr > td:empty{
	height: 40px;
}

/*---------------------------------------------------*/


.flowchart .title{
	background: #0078ab;
	width: 1025px !important;
	vertical-align: middle;
	float: left;
}


.flowchart li{
	list-style-type:none;
}

.flowchart li:before{
	content:'\00b7'; 
	font-size:50px; 
	line-height:24px; 
	vertical-align:middle;
	padding-right:1%;
}

.flowchart ul {
	text-align:justify;
	list-style-image: none !important;
	padding-left: 2%;
}


.flowchart p {
	padding:5px 0 5px 0;
}

.flowchart table tr td:nth-child(2) {
	padding:0 0 3% 12px;
}


/*---------------------------------------------------*/


.pip_checklist ul.square li:before{
	content: "\2610" !important;
	font-size: 20px;
	padding-right:10px;
}


.pip_checklist ul{
	list-style-image: none !important;
	list-style: none;
	display: table;
	margin-left: 30px !important;
}

.pip_checklist ul li{
	margin-bottom: 2px;
}

.pip_checklist p{
	padding: 5px 0 5px 0;
}

.pip_checklist .title{
	background: #0078ab;
	width: 1025px !important;
	vertical-align: middle;
	float: left;
}

.pip_checklist_header {
	border: 1px solid black;
	padding:3px !important;
}


/*---------------------------------------------------*/


.qapiplan h2{
	color: #0078ab !important;
	font-size: 18px;
	text-align: center;
	padding:10px 0 10px 0;
}

.qapiplan ol li {
	padding:3px;
}


.qapiplan .outerlist > li {
	padding-bottom:8px !important;
}

.qapiplan ul {
	list-style-image:none !important;
	list-style:disc!important;
}

/*---------------------------------------------------*/


.performance_objectives ul {
	list-style-image: none !important;
	list-style:none !important;
	padding-left:1%;
}


.performance_objectives p {
	padding:10px 0 10px 0;
}

.performance_objectives li:before{
	content:'\00b7'; 
	font-size:50px; 
	line-height:24px; 
	vertical-align:middle;
	padding-right:1%;
}

/*---------------------------------------------------*/

.complan ul {
	list-style-image: none !important;
	list-style:none !important;
	padding:1%;
}

.complan li {
	padding:3px;
}


.complan li:before{
	content: "\2610" !important;
	font-size: 20px;
	padding-right:10px;
}


.complan p {
	padding:6px 0 6px 0;
}


#approach_table tr, #approach_table th, #approach_table td {
	border: 1px solid black;
	background:#fff;
}

#approach_table tr td:first-child{
	padding:0 7px 0 7px;
}
/*---------------------------------------------------*/

.pdsa_template .title{
	background: #0078ab;
	width: 1025px !important;
	vertical-align: middle;
	float: left;
}

.pdsa_template ul {
	list-style-image: none !important;
	list-style:square !important;
	padding-left:4%;
}


.pdsa_template table, .pdsa_template tr, .pdsa_template td{
	border: 1px solid black !important;
}

.pdsa_template table tr td{
	vertical-align: top;
}


.pdsa_template p {
	margin:0px !important;
}

#improvement_table tr td{
	padding:0 0 4% 3%;
}

#pdsa_table tr td {
	padding-left:5px;
}
.zoomreset
{
	
}

.task-history-popup
{
  position: absolute;
  bottom: 42px;
  left: -136px;
  width: 300px;
  height: auto;
  z-index: 999999999;
}
.history-content
{
  border: 2px solid #e5b143;
  background: #fefbe2;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 4px 0 4px 0;
}
span.arrow-down
{
  z-index:      : 999;
  position      : absolute;
	bottom        : -12px;
	right         : 50%;
	margin        : 0 -12px 0 0;
	border-left   : 12px solid transparent;
	border-right  : 12px solid transparent;
	border-top    : 12px solid #e5b143;
	border-bottom :	none;
}
span.arrow-down:before
{
  content       : "";
	position      : absolute;
	bottom        : 2px;
	right         : -10px;
	border-left   : 10px solid transparent;
	border-right  : 10px solid transparent;
	border-top    : 10px solid #fefbe2;
	border-bottom :	none;
}

.history-content table
{
  width: 100%;
  border-collapse: collapse;
}
.history-content table th
{
	font-weight: bold;
	padding: 0;
	height: auto;
	line-height: 130% !important;
	background: none;
	border-top: 0;
	border-left: 0;
	text-align: center;
}
.history-content table th, .history-content table td
{
  border-bottom: 1px solid #000; 
  border-right: 1px solid #000;
}
.history-content table th span, .history-content table td span
{
  text-align: center;
}
.history-content table tr > th:last-child, .history-content table tr > td:last-child
{
  border-right: 0;
}
.history-content table tr > th:first-child, .history-content table tr > th:nth-child(2), .history-content table tr > td:first-child span, .history-content table tr > td:nth-child(2) span
{
  text-align: center;
}
.history-content table td
{
  position: relative;
}
.history-content table td:before
{
  content: '&nbsp;';
  visibility: hidden;
}
.history-content table td > span
{
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.history-content table tbody tr:last-child td
{
  border-bottom: 0;
}
.history-content table td a
{
	color: #3a86b1 !important;
	text-decoration: underline !important;
}


.indHeaderMonthTable
{
	cursor: pointer;
}
.indHeaderMonthTable:hover:not(.surveyMonth)
{
	-webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
	background: #ff807e;
}

.surveyMonth
{
	cursor: default;
	background-color: #ff807e;
}

.cta {
    font-size:130%;
    color: #d49b00;
}

.relative-user-row {
    position: relative;
    margin-right:26px;
}

.aaUser {
    color: #727272;
    font-weight: bold;
    right: -25px;
    position: absolute;
    top: 10px;
    text-decoration: none;
}

.aaUser img {
    margin-top: -5px;
}
